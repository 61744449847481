'use client'

import { Section, fontSizes, lineHeights, spacing } from '@fortum/elemental-ui'

import { Ivr } from '@/shared/components/Ivr'
import { StyledHeading } from '@/shared/components/StyledHeading'
import type { IvrSectionEntry } from '@/shared/contentful/types'
import { useTheme } from '@/shared/hooks/useTheme'

export type IvrSectionProps = IvrSectionEntry

export const IvrSection = ({ title, solutionTitle, ivrItemsCollection }: IvrSectionProps) => {
  const theme = useTheme()
  const items = ivrItemsCollection?.items ?? []

  return (
    <Section
      paddingTop={spacing.xxl}
      paddingBottom={spacing.m}
      background={theme.colors.backgroundSecondary}
      data-testid="ivr-section"
    >
      {title && (
        <StyledHeading
          level={3}
          marginBottom={spacing.xs}
          textColor={theme.colors.textPrimary}
          fontSize={fontSizes.xl}
          lineHeight={lineHeights.normal}
        >
          {title}
        </StyledHeading>
      )}

      <Ivr solutionTitle={solutionTitle} items={items} />
    </Section>
  )
}
