'use client'

import { Col, Row, spacing } from '@fortum/elemental-ui'

import { Section } from '@/shared/components/Section'
import { SectionError } from '@/shared/components/SectionError'
import { Statistic } from '@/shared/components/Statistic'
import { StyledHeading } from '@/shared/components/StyledHeading'
import type { StatisticsSectionEntry } from '@/shared/contentful/types'
import { useTheme } from '@/shared/hooks/useTheme'

export type StatisticSectionProps = StatisticsSectionEntry & {
  /**
   * Optional color styles.
   */
  colors?: ColorProps<never, 'textColor' | 'titleColor' | 'statColor' | 'backgroundColor'>
}

/**
 * A section that contains a collection of statistic blocks.
 * Allows between 2 and 4 stat elements, if the number will
 * be different it will not render.
 */
export const StatisticSection = ({
  title,
  statisticsSectionItemsCollection,
  colors,
}: StatisticSectionProps) => {
  const theme = useTheme()
  const items = statisticsSectionItemsCollection?.items || []
  const count = items?.length
  const valueColor = colors?.statColor
  const backgroundColor = colors?.backgroundColor
  const titleColor = colors?.titleColor || theme.colors.textLarge
  const textColor = colors?.textColor || theme.colors.textPrimary

  if (count < 2 || count > 4) {
    return <SectionError message="Statistics section supports only 2 to 4 items." />
  }
  /**
   * If we have and odd amount of elements, tablet shows them in pairs.
   * Otherwise - three in the row.
   */
  const tabletColumnSize = !(count % 2) ? 6 : 4

  return (
    <Section
      p={{ default: `${spacing.xs} 0`, l: `${spacing.m} 0`, xxl: `${spacing.m} ${spacing.xxl}` }}
      backgroundColor={backgroundColor}
    >
      <StyledHeading
        level={2}
        styledAs={4}
        mb={{ default: spacing.xs, s: spacing.xxs, l: spacing.xs }}
        color={titleColor}
      >
        {title}
      </StyledHeading>
      <Row alignItems="flex-start" gap={{ default: `${spacing.xs} 0`, s: `${spacing.xxs} 0` }}>
        {items.map((props, i) => (
          <Col key={i} xxs={12} s={tabletColumnSize} l={12 / count}>
            {props && <Statistic {...props} colors={{ valueColor, textColor }} />}
          </Col>
        ))}
      </Row>
    </Section>
  )
}
