'use client'

import { Col, Row } from '@fortum/elemental-ui'

import { Section } from '@/shared/components/Section'
import type { BenefitsSectionEntry } from '@/shared/contentful/types'
import { useTheme } from '@/shared/hooks/useTheme'

import { BenefitsBigImage } from './parts/BenefitsBigImage'
import { BenefitsCarousel } from './parts/BenefitsCarousel'
import { BenefitsSplit } from './parts/BenefitsSplit'

export type BenefitsSectionProps = BenefitsSectionEntry
type ColorVariant = 'default' | 'secondary'
type Variant = 'bigImage' | 'carousel' | 'split'
/**
 * Section which presents content features with text and description in many layout variants.
 */
export const BenefitsSection = ({
  variant = 'split',
  colorVariant = 'default',
  ...props
}: BenefitsSectionProps) => {
  const { colors } = useTheme()

  const backgroundColors: Record<ColorVariant, string> = {
    default: colors.backgroundPrimary,
    secondary: colors.backgroundSecondary,
  }

  return (
    <Section backgroundColor={backgroundColors[colorVariant as ColorVariant]}>
      <Row justifyContent="center">
        <Col xxs={12} xl={10}>
          {
            {
              split: <BenefitsSplit {...props} />,
              carousel: <BenefitsCarousel {...props} />,
              bigImage: <BenefitsBigImage {...props} />,
            }[variant as Variant]
          }
        </Col>
      </Row>
    </Section>
  )
}
