'use client'

import styled from 'styled-components'
import { match } from 'ts-pattern'

import type { BoxPropValue, CommonBoxProps, HeadingLevel } from '@fortum/elemental-ui'
import { Box, ContentText, px2rem, shadows, spacing } from '@fortum/elemental-ui'

import type { ImageEntry } from '@/shared/contentful/types'
import { useTheme } from '@/shared/hooks/useTheme'
import * as styleMixins from '@/shared/style/styleMixins'

import { ContentfulImage2 } from '../ContentfulImage2'
import { StyledHeading } from '../StyledHeading'
import { StyledLink } from '../StyledLink'

export type ColorVariant = 'default' | 'inverted'

export type PageCardAdditionalProps = {
  colorVariant?: ColorVariant | null
  boxProps?: CommonBoxProps
  imageAspectRatio?: BoxPropValue
  imagePriority?: boolean
  imageSizes?: string
  headingStyledAs?: HeadingLevel
}

export type PageCardProps = PageCardAdditionalProps & {
  url: string
  image?: ImageEntry | null
  topic?: string
  title?: string
  description?: string
}

/**
 * Card that links to a page or article.
 */
export const PageCard = ({
  image,
  topic,
  title,
  description,
  url,
  colorVariant = 'default',
  boxProps,
  imageAspectRatio = '2.2/1',
  imagePriority,
  imageSizes,
  headingStyledAs,
}: PageCardProps) => {
  const theme = useTheme()
  const { colors } = theme

  const variantColors = match(colorVariant)
    .with('inverted', () => ({
      background: colors.backgroundLightOnDark,
      topic: colors.textLightOnDarkAccent,
      title: colors.textLightOnDark,
      description: colors.textLightOnDark,
      hover: `0 0 ${px2rem(27)} ${px2rem(8)} rgba(255, 255, 255, 0.3)`,
    }))
    .otherwise(() => ({
      background: colors.backgroundPrimary,
      topic: colors.textPositive,
      title: colors.textLarge,
      description: colors.textSecondary,
      hover: shadows.m,
    }))
  return (
    <StyledLink
      href={url}
      backgroundColor={variantColors.background}
      display="flex"
      flexDirection="column"
      noUnderlineHover
      borderRadius={spacing.xxxs}
      hover={{ boxShadow: variantColors.hover }}
      overflow="hidden"
      data-testid="page-card"
      {...boxProps}
    >
      <Box position="relative" backgroundColor={colors.primary} aspectRatio={imageAspectRatio}>
        {image && (
          <ContentfulImage2 fill image={image} priority={imagePriority} sizes={imageSizes} />
        )}
      </Box>
      <Box
        p={{ default: spacing.xxs, l: `${spacing.xxs} ${spacing.xs} ${spacing.xs}` }}
        display="flex"
        flexDirection="column"
      >
        {topic && (
          <Topic size="s" color={variantColors.topic}>
            {topic}
          </Topic>
        )}
        {title && (
          <StyledHeading level={5} styledAs={headingStyledAs} color={variantColors.title}>
            {title}
          </StyledHeading>
        )}
        {description && (
          <Description color={variantColors.description} size="s" mt={spacing.xxxs}>
            {description}
          </Description>
        )}
      </Box>
    </StyledLink>
  )
}

const Topic = styled(ContentText)`
  ${styleMixins.truncate}
`

const Description = styled(ContentText)`
  ${styleMixins.truncateMultiLineText(2)}
`
