'use client'

import styled from 'styled-components'

import type { BoxPropValue, CommonBoxProps } from '@fortum/elemental-ui'
import {
  Box,
  Col,
  ContentText,
  Row,
  fontSizes,
  fontWeights,
  px2rem,
  spacing,
} from '@fortum/elemental-ui'

import { Video } from '@/shared/components/Video'
import type { QuoteEntry } from '@/shared/contentful/types'
import { useTheme } from '@/shared/hooks/useTheme'
import * as styleMixins from '@/shared/style/styleMixins'

import { ContentfulImage2 } from '../ContentfulImage2'
import type { MediaItemProps } from '../MediaItem'

export type MediaTestimonialProps = Omit<QuoteEntry, 'sys' | '__typename'> & MediaItemProps

/**
 * Component with customers quote and image or video.
 */
export const MediaTestimonial = ({
  author,
  authorDescription,
  simpleContent,
  imageEntry,
  videoEntry,
}: MediaTestimonialProps) => {
  const theme = useTheme()
  const hasMedia = imageEntry || videoEntry

  const mediaBorderRadius: BoxPropValue = {
    default: `${spacing.xxxs} ${spacing.xxxs} 0 0`,
    l: `${spacing.xxxs} 0 0 ${spacing.xxxs}`,
  }

  const imageBoxProps: CommonBoxProps = {
    borderRadius: mediaBorderRadius,
    aspectRatio: '16/9',
    height: '100%',
  }

  return (
    <Row margin="0 !important">
      <Col
        padding="0 !important"
        xxs={12}
        l={8}
        minHeight={{ default: 0, l: '25rem' }}
        position="relative"
        borderRadius={spacing.m}
      >
        {!hasMedia ? (
          <ContentfulImage2 boxProps={imageBoxProps} image={undefined} />
        ) : (
          <>
            {imageEntry && <ContentfulImage2 boxProps={imageBoxProps} image={imageEntry} />}

            {videoEntry && (
              <Video
                video={videoEntry}
                boxProps={{
                  borderRadius: mediaBorderRadius,
                  objectFit: 'cover',
                  backgroundColor: theme.colors.borderPrimary,
                }}
              />
            )}
          </>
        )}
      </Col>

      <Col
        xxs={12}
        l={4}
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        alignSelf="stretch"
        gap={{ default: spacing.xs, s: spacing.m }}
        p={{ default: spacing.xs, xs: spacing.xs, s: spacing.m }}
        backgroundColor={theme.colors.backgroundPrimary}
        borderRadius={{
          default: `0 0 ${spacing.xxxxs} ${spacing.xxxxs} `,
          l: `0 ${spacing.xxxxs} ${spacing.xxxxs} 0`,
        }}
        borderTop={{
          default: `0 solid ${theme.colors.borderPrimary}`,
          l: `1px solid ${theme.colors.borderPrimary}`,
        }}
        borderRight={{
          default: `1px solid ${theme.colors.borderPrimary}`,
          l: `1px solid ${theme.colors.borderPrimary}`,
        }}
        borderBottom={{
          default: `1px solid ${theme.colors.borderPrimary}`,
          l: `1px solid ${theme.colors.borderPrimary}`,
        }}
        borderLeft={{
          default: `1px solid ${theme.colors.borderPrimary}`,
          l: `0 solid ${theme.colors.borderPrimary}`,
        }}
        textAlign="left"
      >
        <Content
          fontSize={fontSizes.xl}
          color={theme.colors.textPrimary}
          maxHeight={{ default: '100%', l: `calc(100% - ${author?.length ? px2rem(60) : 0})` }}
          maxWidth="100%"
        >
          <q>{simpleContent}</q>
        </Content>

        {!!author?.length && (
          <Box>
            <ContentText
              fontSize={fontSizes.l}
              color={theme.colors.textPrimary}
              fontWeight={{ default: fontWeights.medium, l: fontWeights.default }}
              textAlign="left"
            >
              {author}
            </ContentText>
            {!!authorDescription?.length && (
              <ContentText textColor={theme.colors.textSecondary} fontSize={fontSizes.m}>
                {authorDescription}
              </ContentText>
            )}
          </Box>
        )}
      </Col>
    </Row>
  )
}

const Content = styled(ContentText)`
  ${styleMixins.truncateMultiLineText({ default: 1000, l: 8 })}
`
