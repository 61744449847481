'use client'

import { useTranslations } from 'next-intl'
import type { FC, ReactNode } from 'react'

import type { BoxPropValue, IconProps } from '@fortum/elemental-ui'
import {
  Box,
  ContentText,
  IconError,
  IconInfo,
  Notification,
  breakpoints,
  spacing,
} from '@fortum/elemental-ui'

import { Icon } from '@/shared/components/Icon'
import { useTheme } from '@/shared/hooks/useTheme'
import type { Theme } from '@/shared/providers/theme'

type NotificationStyle = {
  background: keyof Theme['colors']
  textColor: keyof Theme['colors']
  icon: FC<IconProps>
}

type NotificationStylesType = {
  [key: string]: NotificationStyle
}

type NotificationType = 'warning' | 'alert'

const notificationStyles: NotificationStylesType = {
  warning: {
    background: 'backgroundWarning',
    textColor: 'textWarning',
    icon: IconInfo,
  },
  alert: {
    background: 'backgroundAlert',
    textColor: 'textAlert',
    icon: IconError,
  },
}

/**
 * A component to show a notification message
 */

export const NotificationBanner: React.FC<{
  type: NotificationType
  heading?: string
  message?: string
  children?: ReactNode
  numberOfLines?: BoxPropValue
  shouldHideCloseButton?: boolean
}> = ({ type, heading, message, children, numberOfLines, shouldHideCloseButton }) => {
  const theme = useTheme()
  const t = useTranslations('serviceNotification')
  return (
    <Box
      background={theme.colors[notificationStyles[type].background]}
      display="flex"
      justifyContent="center"
      width="100%"
      role="alert"
    >
      <Box maxWidth={`${breakpoints.xl}px`} width="100%">
        <Notification
          iconButtonProps={{ 'aria-label': t('iconAriaLabel') }}
          backgroundColor={theme.colors[notificationStyles[type].background]}
          textColor={theme.colors[notificationStyles[type].textColor]}
          icon={<Icon icon={notificationStyles[type].icon} marginRight={spacing.xxs} />}
          heading={heading}
          hideCloseButton={shouldHideCloseButton}
          numberOfLines={numberOfLines}
        >
          {message ? <ContentText>{message}</ContentText> : children}
        </Notification>
      </Box>
    </Box>
  )
}
