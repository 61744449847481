'use client'

import { Box, spacing } from '@fortum/elemental-ui'

import { App } from '@/shared/components/App'
import { Section } from '@/shared/components/Section'
import { SmartBanner } from '@/shared/components/SmartBanner'
import type { SmartBannerProps } from '@/shared/components/SmartBanner'
import type { AppSectionEntry } from '@/shared/contentful/types'
import { useTheme } from '@/shared/hooks/useTheme'

export type AppSectionProps = AppSectionEntry & {
  /**
   * Smart banner
   */
  banner?: SmartBannerProps
  /**
   * QR code data URL
   */
  qrCodeDataUrl?: string
}
/**
 * App section with mobile app downloads
 */
export const AppSection = ({
  banner,
  backgroundColor,
  qrCodeDataUrl,
  ...appSectionProps
}: AppSectionProps) => {
  const theme = useTheme()
  const background =
    backgroundColor === 'solid'
      ? theme.colors.backgroundLightOnDark
      : theme.colors.backgroundLightOnDarkGradient
  return (
    <Box background={background}>
      {banner && <SmartBanner {...banner} />}
      {appSectionProps && (
        <Section pv={{ default: spacing.xs, l: spacing.m }}>
          <App qrCodeDataUrl={qrCodeDataUrl} {...appSectionProps} />
        </Section>
      )}
    </Box>
  )
}
