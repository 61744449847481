'use client'

import styled from 'styled-components'

import { Box, ContentText, Heading, fontWeights, lineHeights, spacing } from '@fortum/elemental-ui'

import type { UspEntry } from '@/shared/contentful/types'
import { getLinkEntryUrl } from '@/shared/contentful/utils'
import { useTheme } from '@/shared/hooks/useTheme'
import { truncateMultiLineText } from '@/shared/style/styleMixins'

import type { IconId } from '../Icon'
import { Icon } from '../Icon'
import { StyledLink } from '../StyledLink'

export type UspProps = UspEntry & {
  colors?: ColorProps<never, 'text' | 'heading' | 'link'>
}

/**
 * Default component designed for unique selling propositions.
 */
export const Usp = ({ title, content, link, icon, colors = {} }: UspProps) => {
  const theme = useTheme()
  const headingColor = colors.heading ?? theme.colors.textLarge
  const textColor = colors.text ?? theme.colors.textPrimary
  const linkColor = colors.link ?? theme.colors.textLink

  return (
    <Box
      data-testid="usp-box"
      key={title}
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap={spacing.xxxs}
      textAlign="center"
    >
      {icon && <Icon icon={icon as IconId} color={headingColor} size={48} />}
      <StyledHeading
        level={3}
        styledAs={5}
        weight={fontWeights.medium}
        lineHeight={lineHeights.compact}
        color={headingColor}
      >
        {title}
      </StyledHeading>
      <ContentText color={textColor}>{content}</ContentText>
      {link && (
        <StyledLink href={getLinkEntryUrl(link)} mt={spacing.xxxs} color={linkColor}>
          {link.label}
        </StyledLink>
      )}
    </Box>
  )
}

const StyledHeading = styled(Heading)`
  ${truncateMultiLineText(3)}
`
