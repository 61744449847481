'use client'

import { match } from 'ts-pattern'

import { Box, Col, Row, Section, spacing } from '@fortum/elemental-ui'

import { ForwardLink } from '@/shared/components/ForwardLink'
import type { ColorVariant } from '@/shared/components/PageCard/PageCardWrapper'
import { PageCardWrapper } from '@/shared/components/PageCard/PageCardWrapper'
import { SectionError } from '@/shared/components/SectionError'
import { StyledHeading } from '@/shared/components/StyledHeading'
import type { SuggestedPagesSectionEntry } from '@/shared/contentful/types'
import { getLinkEntryUrl } from '@/shared/contentful/utils'
import { useTheme } from '@/shared/hooks/useTheme'
import { rejectNil } from '@/shared/utils/array'
import { getSizesForColumns } from '@/shared/utils/image'

export type SuggestedPagesSectionProps = Omit<
  SuggestedPagesSectionEntry,
  'name' | 'pagesCollection' | '__typename' | 'sys'
> & {
  showDescriptions?: boolean
  pages?: NonNullable<SuggestedPagesSectionEntry['pagesCollection']>['items']
}

/**
 * Section which presents one to three page or article cards.
 */
export const SuggestedPagesSection = (props: SuggestedPagesSectionProps) => {
  const { title, link, colorVariant = 'default', showDescriptions = true } = props
  const pages = rejectNil(props.pages ?? [])
  const count = pages.length

  const { colors } = useTheme()

  const variantColors = match(colorVariant)
    .with('inverted', () => ({
      background: colors.backgroundLightOnDarkGradient,
      title: colors.textLightOnDark,
      link: colors.textLightOnDark,
    }))
    .otherwise(() => ({
      background: colors.backgroundSecondary,
      title: colors.textLarge,
      link: colors.primary,
    }))

  if (count < 1 || count > 3) {
    return <SectionError message="Suggested pages section supports only 1 to 3 items." />
  }

  return (
    <Section pv={spacing.m} background={variantColors.background}>
      <Box display="flex" flexDirection="column" gap={spacing.xs}>
        {title && (
          <StyledHeading level={2} styledAs={4} color={variantColors.title}>
            {title}
          </StyledHeading>
        )}
        <Row rowGap={spacing.xs}>
          {pages.map((page, index) => (
            <Col key={index} m={count === 2 ? 6 : 4} display="grid">
              <PageCardWrapper
                page={page}
                showDescription={showDescriptions}
                colorVariant={colorVariant as ColorVariant}
                imageSizes={getSizesForColumns({ m: 6, l: 6 })}
              />
            </Col>
          ))}
        </Row>
        {link && (
          <Box display="flex" justifyContent="flex-end">
            <ForwardLink color={variantColors.link} href={getLinkEntryUrl(link)}>
              {link.label}
            </ForwardLink>
          </Box>
        )}
      </Box>
    </Section>
  )
}
