'use client'

import { DateTime } from 'luxon'
import { useContext, useEffect, useState } from 'react'
import styled, { css, keyframes } from 'styled-components'
import { z } from 'zod'

import { Box, ContentText, IconCross, IconFortum, spacing } from '@fortum/elemental-ui'

import type { AppLinksEntry } from '@/shared/contentful/types'
import { useBreakpoints } from '@/shared/hooks/useBreakpoints'
import { useTheme } from '@/shared/hooks/useTheme'
import { PopupContext } from '@/shared/providers/popup'
import * as styleMixins from '@/shared/style/styleMixins'
import type { PopupContextType } from '@/shared/types/Context'
import { getLocalStorageValue, setLocalStorageValue } from '@/shared/utils/localStorage'

import { Icon } from '../Icon'
import { StyledLink } from '../StyledLink'

export type SmartBannerProps = {
  /**
   * Title
   */
  title: string
  /**
   * Content
   */
  content: string
  /**
   * app URLS
   */
  appLinks: AppLinksEntry
  /**
   * Link text
   */
  linkText?: string
}

type SmartBannerData = {
  expireDate: string
}

/**
 * Smart banner component for mobile devices to promote Fortum app
 */
export const SmartBanner = ({ title, content, appLinks, linkText }: SmartBannerProps) => {
  const [isClosing, setIsClosing] = useState(false)
  // Set visibility based on the local storage value
  const [isVisible, setIsVisible] = useState(false)
  const theme = useTheme()
  const { isMobile } = useBreakpoints()

  const LOCAL_STORAGE_KEY = 'closed-smartbanner'

  const getClosedBanner = () => {
    return getLocalStorageValue(LOCAL_STORAGE_KEY, z.string(), '')
  }

  const setClosedBanner = (closedBanner: string) => {
    return setLocalStorageValue(LOCAL_STORAGE_KEY, closedBanner)
  }

  const popupContext = useContext(PopupContext)
  const { shouldShowSmartBanner, setShouldShowSmartBanner } = popupContext as PopupContextType

  //TODO update localization when it's available
  const url = appLinks?.googlePlayLink ?? appLinks?.appStoreLink ?? ''

  useEffect(() => {
    const isSmartBannerEnabled = (): boolean => {
      const storedData = getClosedBanner()
      if (!storedData) {
        return true
      }

      const parsedData = JSON.parse(storedData) as SmartBannerData
      const { expireDate } = parsedData
      return DateTime.fromISO(expireDate) < DateTime.now()
    }

    const isEnabled = isSmartBannerEnabled()
    setShouldShowSmartBanner(isEnabled)
    setIsVisible(isEnabled)
  }, [setShouldShowSmartBanner])

  const handleClose = () => {
    setIsClosing(true)
    setTimeout(() => setIsVisible(false), 500)
    if (typeof window !== 'undefined') {
      const expireDate = DateTime.now().plus({ months: 3 }).toISO()
      setClosedBanner(JSON.stringify({ expireDate }))
    }
  }

  if (!isVisible || !isMobile) {
    return null
  }

  return (
    <BannerBox
      isClosing={isClosing}
      hidden={!shouldShowSmartBanner}
      display="flex"
      alignItems="center"
      backgroundColor={theme.colors.backgroundPrimary}
      p="0.625rem 1rem 0.625rem 1.25rem"
    >
      <Box display="flex" width="100%" justifyContent="space-between" alignItems="center">
        <Box display="flex">
          <StyledButton onClick={handleClose}>
            <Icon icon={IconCross} color={theme.colors.textPrimary} size={spacing.xxs} />
          </StyledButton>
          <StyledLink alignItems="center" noUnderline href={url} gap={spacing.xxs} display="flex">
            <Box
              width="3.125rem"
              height="3.125rem"
              borderRadius={spacing.xxxs}
              background={theme.colors.brandPrimary}
              display="flex"
              justifyContent="center"
            >
              <Icon icon={IconFortum} size={spacing.s} color={theme.colors.textLightOnDark} />
            </Box>
            <Box
              width={{ default: '10.188rem', xs: '85%' }}
              display="flex"
              flexDirection="column"
              gap={spacing.xxxxs}
            >
              <Text color={theme.colors.textPrimary}>{title}</Text>
              <Text color={theme.colors.textSecondary}>{content}</Text>
            </Box>
          </StyledLink>
        </Box>
        <Box>
          <StyledLink href={url} color={theme.colors.textLink}>
            {linkText || 'view'}
          </StyledLink>
        </Box>
      </Box>
    </BannerBox>
  )
}

const Text = styled(ContentText)`
  ${styleMixins.truncate}
`

const slideUp = keyframes`
  from {
    transform: translateY(0);
    height: auto; 
  }
  to {
    transform: translateY(-200%);
    height: 0;
    padding: 0;
  }
`

interface BannerBoxProps {
  isClosing: boolean
}

const BannerBox = styled(Box)<BannerBoxProps>`
  transition:
    transform 0.5s ease-in-out,
    height 0.5s ease-in-out;
  overflow: hidden;

  ${({ isClosing }) =>
    isClosing &&
    css`
      animation: ${slideUp} 0.5s forwards;
    `}
`

const StyledButton = styled.button`
  border: none;
  cursor: pointer;
  padding: 1.25rem 1.25rem 1.25rem 0;
  background-color: ${({ theme }) => theme.colors.backgroundPrimary};
  display: flex;
  align-items: center;
`
