'use client'

import { Box, Hidden, Section, spacing } from '@fortum/elemental-ui'

import type { ProductDetailsItemEntry } from '@/shared/contentful/types'
import { useTheme } from '@/shared/hooks/useTheme'

import { ProductDetailsAccordionDesktop } from './parts/ProductDetailsAccordionDesktop'
import { ProductDetailsAccordionMobile } from './parts/ProductDetailsAccordionMobile'

export type ProductDetailsSectionProps = {
  items: ProductDetailsItemEntry[]
}

/**
 * Section with a list of items that describe product details.
 * Supports separate mobile and desktop layouts with responsive styles.
 * Functions as an accordion: https://www.w3.org/WAI/ARIA/apg/patterns/accordion/
 */
export const ProductDetailsSection = ({ items }: ProductDetailsSectionProps) => {
  const theme = useTheme()

  return (
    <Box backgroundColor={theme.colors.backgroundSecondary}>
      <Section paddingVertical={{ default: spacing.m, l: spacing.xxl }}>
        <Hidden above="l">
          <ProductDetailsAccordionMobile items={items} />
        </Hidden>
        <Hidden below="l">
          <ProductDetailsAccordionDesktop items={items} />
        </Hidden>
      </Section>
    </Box>
  )
}
