'use client'

import { INLINES } from '@contentful/rich-text-types'

import { Box, Section, spacing } from '@fortum/elemental-ui'

import type { RichTextDocument } from '@/shared/components/RichText'
import { RichText } from '@/shared/components/RichText'
import { StyledHeading } from '@/shared/components/StyledHeading'
import type { InPageFaqEntry } from '@/shared/contentful/types'
import { useTheme } from '@/shared/hooks/useTheme'

import { InPageFaqAccordion } from './parts/InPageFaqAccordion'

export type InPageFaqSectionProps = {
  title?: string | null
  content?: RichTextDocument | null
  items: InPageFaqEntry[]
}

/**
 * Section for showing frequently asked questions within the page.
 * Functions as an accordion: https://www.w3.org/WAI/ARIA/apg/patterns/accordion/
 */
export const InPageFaqSection = ({ content, items, title }: InPageFaqSectionProps) => {
  const theme = useTheme()

  return (
    <Section pv={{ default: spacing.xs, s: spacing.s, l: spacing.m }}>
      <Box display="flex" flexDirection="column" gap={{ default: spacing.xs, s: spacing.m }}>
        <Box>
          <StyledHeading color={theme.colors.textPrimary} level={3} styledAs={4} mb={spacing.xxxs}>
            {title}
          </StyledHeading>
          <Box textColor={theme.colors.textSecondary}>
            <RichText
              document={content}
              customOptions={{
                renderNode: {
                  [INLINES.HYPERLINK]: (node, children) => (
                    <a
                      href={node.data.uri}
                      target="_blank"
                      style={{ color: theme.colors.textSecondary }}
                    >
                      {children}
                    </a>
                  ),
                },
              }}
            />
          </Box>
        </Box>
        <InPageFaqAccordion items={items} title={title} />
      </Box>
    </Section>
  )
}
