'use client'

import { useTranslations } from 'next-intl'
import styled from 'styled-components'

import {
  Box,
  Dots,
  IconButton,
  IconChevronLeft,
  IconChevronRight,
  spacing,
} from '@fortum/elemental-ui'

import { useTheme } from '@/shared/hooks/useTheme'

import { Icon } from '../../Icon'

export type StepperProps = {
  /**
   * The total number of elements in the stepper
   */
  elements: number
  /**
   * The number of active elements in the stepper
   */
  activeElements: number
  /**
   * The index of the first active element in the stepper
   */
  activeElementIndex: number
  /**
   * Whether the stepper is cycled. If true, it indicates that both prev and next buttons
   * are inactive when it reaches the start/end.
   */
  cycled?: boolean
  /**
   * Callback for the next button
   */
  onNextClick: VoidFunction
  /**
   * Callback for the previous button
   */
  onPreviousClick: VoidFunction
  /**
   * Custom colors to override default ones
   */
  colors: ColorProps<never, 'button' | 'dot' | 'dotActive'>
}

/**
 * A component that takes in number of elements in a carousel and renders a stepper
 */
export const Stepper = ({
  elements,
  activeElements,
  activeElementIndex,
  cycled = true,
  onPreviousClick,
  onNextClick,
  colors = {},
}: StepperProps) => {
  const theme = useTheme()
  const {
    button = theme.colors.brandPrimary,
    dotActive = theme.colors.brandPrimary,
    dot = theme.colors.textDisabled,
  } = colors

  const t = useTranslations('stepper')

  const isPreviousDisabled = !cycled && activeElementIndex === 0
  const isNextDisabled = !cycled && activeElementIndex + activeElements >= elements

  return (
    <Box display="flex" gap={spacing.xxs} alignItems="center" justifyContent="center">
      <Box display="flex">
        <CustomDisableIconButton
          data-testid="stepper-button-left"
          color={button}
          status="secondary"
          icon={<Icon icon={IconChevronLeft} />}
          round
          disabled={isPreviousDisabled}
          aria-label={t('previousButton')}
          onClick={() => {
            onPreviousClick()
          }}
        />
      </Box>
      <Dots
        count={elements}
        active={activeElementIndex}
        inactiveColor={dot}
        activeColor={dotActive}
      />
      <Box display="flex" justifyContent="flex-end">
        <CustomDisableIconButton
          data-testid="stepper-button-right"
          color={button}
          status="secondary"
          icon={<Icon icon={IconChevronRight} />}
          round
          disabled={isNextDisabled}
          aria-label={t('nextButton')}
          onClick={() => {
            onNextClick()
          }}
        />
      </Box>
    </Box>
  )
}

const CustomDisableIconButton = styled(IconButton)`
  background-color: transparent;

  :disabled,
  [disabled] {
    color: ${({ theme }) => theme.colors.textDisabled};
    border: 2px solid ${({ theme }) => theme.colors.textDisabled};
    background-color: transparent;
  }
`
