import type { TouchEvent } from 'react'
import { useState } from 'react'

export const useSwipeHandlers = (handleNext: VoidFunction, handlePrevious: VoidFunction) => {
  const [touchStartPosition, setTouchStartPosition] = useState(0)

  /**
   * Logic for swipe gestures when starting the swipe
   */
  const handleSwipeStart = (event: TouchEvent<HTMLDivElement>) => {
    setTouchStartPosition(event.touches[0].clientX)
  }

  /**
   * Logic for swipe gestures when swipe has ended
   */
  const handleSwipeEnd = (event: TouchEvent<HTMLDivElement>) => {
    const touchEndPosition = event.changedTouches[0].clientX
    const deltaX = touchEndPosition - touchStartPosition

    if (deltaX > 0) {
      handlePrevious()
    } else {
      handleNext()
    }
  }

  return {
    handleSwipeStart,
    handleSwipeEnd,
  }
}
