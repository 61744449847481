'use client'

import styled from 'styled-components'

import { Box, ContentText } from '@fortum/elemental-ui'

import type { StatisticEntry } from '@/shared/contentful/types'
import { useTheme } from '@/shared/hooks/useTheme'
import * as styleMixins from '@/shared/style/styleMixins'

import { StyledHeading } from '../StyledHeading'

export type StatisticProps = StatisticEntry & {
  /**
   * Optional color styles.
   */
  colors?: ColorProps<never, 'valueColor' | 'textColor'>
}

/**
 * A block that shows single Statistic, its title and description.
 */
export const Statistic = ({ value, title, content, colors }: StatisticProps) => {
  const theme = useTheme()
  const valueColor = colors?.valueColor || theme.colors.textPositive
  const textColor = colors?.textColor || theme.colors.textPrimary

  return (
    <Box>
      <StyledHeading tag="p" styledAs={2} color={valueColor}>
        {value}
      </StyledHeading>
      <Title size="xl" color={textColor} title={title || ''}>
        {title}
      </Title>
      <Description size="s" color={textColor} title={content || ''}>
        {content}
      </Description>
    </Box>
  )
}

const Title = styled(ContentText)`
  ${styleMixins.truncateMultiLineText({ default: 3, s: 2 })}
`

const Description = styled(ContentText)`
  ${styleMixins.truncateMultiLineText({ default: 5, s: 4 })}
`
