'use client'

import { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { z } from 'zod'

import type { AnnouncementEntry } from '@/shared/contentful/types'
import { getLocalStorageValue, setLocalStorageValue } from '@/shared/utils/localStorage'

import { ANNOUNCEMENT_PLACEHOLDER_ID } from './keys'

import { Announcement } from '.'

export type AnnouncementContainerProps = AnnouncementEntry

export const AnnouncementContainer = ({ sys, ...fields }: AnnouncementContainerProps) => {
  const [container, setContainer] = useState<Element>()
  const [isOpened, setIsOpened] = useState(true)
  const [shouldShowAnnouncement, setShouldShowAnnouncement] = useState(false)
  const [shouldSlideDown, setShouldSlideDown] = useState(false)

  const LOCAL_STORAGE_ANNOUNCEMENT_KEY = 'closed-announcements'
  const LOCAL_STORAGE_SLIDEDOWN_KEY = 'slidedown-animation'

  const { id } = sys

  const getClosedAnnouncements = () => {
    return getLocalStorageValue(LOCAL_STORAGE_ANNOUNCEMENT_KEY, z.array(z.string()), [])
  }
  const setClosedAnnouncements = (closedAnnouncements: string[]) => {
    setLocalStorageValue(LOCAL_STORAGE_ANNOUNCEMENT_KEY, closedAnnouncements)
  }

  const getSlidedownAnimation = () => {
    return getLocalStorageValue(LOCAL_STORAGE_SLIDEDOWN_KEY, z.array(z.string()), [])
  }
  const setSlidedownAnimation = (shouldSlideDown: string[]) => {
    setLocalStorageValue(LOCAL_STORAGE_SLIDEDOWN_KEY, shouldSlideDown)
  }

  useEffect(() => {
    setContainer(document.querySelector(`#${ANNOUNCEMENT_PLACEHOLDER_ID}`) ?? undefined)
  }, [])

  useEffect(() => {
    const isAnnouncementEnabled = (): boolean => {
      const closedAnnouncements = getClosedAnnouncements()
      return !closedAnnouncements.includes(id)
    }
    const showAnnouncement = isAnnouncementEnabled()
    setIsOpened(showAnnouncement)
    setShouldShowAnnouncement(showAnnouncement)

    const isSlidedownAnimationEnabled = (): boolean => {
      const slidedownAnimation = getSlidedownAnimation()
      return !slidedownAnimation.includes(id)
    }
    const showSlidedown = isSlidedownAnimationEnabled()
    setShouldSlideDown(showSlidedown)

    const disableSlidedownAnimation = () => {
      setSlidedownAnimation([...getSlidedownAnimation(), id])
    }
    if (showSlidedown) {
      setTimeout(() => {
        disableSlidedownAnimation()
      }, 2000)
    }
  }, [id])

  const handleClose = () => {
    setIsOpened(false)
    const closedAnnouncements = getClosedAnnouncements()
    setClosedAnnouncements([...closedAnnouncements, id])
  }

  const announcement = (
    <Announcement
      {...fields}
      shouldSlideDown={shouldSlideDown}
      isOpened={isOpened}
      handleClose={handleClose}
    />
  )

  if (!shouldShowAnnouncement) {
    return null
  }

  if (!container) {
    return null
  }

  return createPortal(announcement, container)
}
