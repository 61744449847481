import { useTranslations } from 'next-intl'

import { ContentText } from '@fortum/elemental-ui'

import { useTheme } from '@/shared/hooks/useTheme'

import { NotificationBanner } from '../NotificationBanner'

export type CoopNotificationProps = {
  /**
   * Trigger different notification message, if fail is caused by API issues
   */
  isApiFail?: boolean
}

export const CoopNotification = ({ isApiFail }: CoopNotificationProps) => {
  const t = useTranslations('coopNotification')
  const { colors } = useTheme()

  const informationType = isApiFail ? 'validationApiFail' : 'invalidCode'

  return (
    <NotificationBanner
      type="alert"
      heading={t('title')}
      shouldHideCloseButton={true}
      numberOfLines={12}
    >
      <ContentText paragraph size="s">
        {t(`${informationType}.information`)}
      </ContentText>
      {!isApiFail && (
        <ContentText paragraph size="s" color={colors.textSecondary}>
          {t('invalidCode.disclaimer')}
        </ContentText>
      )}
    </NotificationBanner>
  )
}
