import type { BlockColorVariant, ColorVariant } from './types'

export const getBlockColorVariant = (
  colorVariant: ColorVariant | undefined | null,
  isSecondary: boolean,
): BlockColorVariant => {
  switch (colorVariant) {
    case 'secondary':
      return 'secondary'
    case 'inverted':
      return 'inverted'
    case 'secondaryInverted':
      return 'inverted'
    case 'mixed':
      return isSecondary ? 'inverted' : 'default'
    case 'secondaryMixed':
      return isSecondary ? 'inverted' : 'secondary'
    case 'default':
    default:
      return 'default'
  }
}
