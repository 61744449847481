'use client'
import { Col, Row, Section, spacing } from '@fortum/elemental-ui'

import { StyledHeading } from '@/shared/components/StyledHeading'
import type { LinkSectionEntry } from '@/shared/contentful/types'

import type { LinkItemProps } from './LinkItem'
import { LinkItem } from './LinkItem'

export type LinkSectionProps = LinkSectionEntry | Omit<LinkSectionEntry, '__typename' | 'sys'>

/**
 * Minimum and maximum number of items to display in the link section
 * @param items
 * @returns
 */
const getMinMaxLinkItems = (
  items: NonNullable<LinkSectionEntry['linkSectionItemsCollection']>['items'] | undefined | null,
) => {
  const filteredItems = items?.filter(Boolean) ?? []
  if (filteredItems.length < 2) {
    return []
  }
  return filteredItems.slice(0, 4)
}

export const LinkSection = ({
  title,
  linkSectionItemsCollection,
  linkVariant,
}: LinkSectionProps) => {
  const filteredItems = getMinMaxLinkItems(linkSectionItemsCollection?.items)
  return (
    <Section pv={{ default: spacing.xs, l: spacing.m }}>
      {title && (
        <StyledHeading level={2} styledAs={4} marginBottom={spacing.xs}>
          {title}
        </StyledHeading>
      )}
      <Row rowGap={{ default: spacing.xs, s: spacing.xxs }}>
        {filteredItems.map((item) => (
          <Col key={item.name} s={filteredItems.length === 3 ? 4 : 6} l={12 / filteredItems.length}>
            <LinkItem item={item} linkVariant={linkVariant as LinkItemProps['linkVariant']} />
          </Col>
        ))}
      </Row>
    </Section>
  )
}
