import type { PriceAreaCode } from '@/shared/graphql/schema/commonBackend/graphql'

import { browserEnvs } from '../envs'

const country = browserEnvs.NEXT_PUBLIC_COUNTRY

export const getPriceAreas = (): PriceAreaCode[] => {
  switch (country) {
    case 'FI':
      return ['FI']
    case 'SE':
      return ['SE1', 'SE2', 'SE3', 'SE4']
    case 'NO':
      return ['NO1', 'NO2', 'NO3', 'NO4', 'NO5']
    default:
      return []
  }
}
