'use client'

import { useTranslations } from 'next-intl'

import type { BreadcrumbItem } from '@fortum/elemental-ui'
import { Box, Breadcrumb, Grid, spacing } from '@fortum/elemental-ui'

import { countryConfig } from '@/shared/countryConfig'
import { useTheme } from '@/shared/hooks/useTheme'
import { Link } from '@/shared/navigation'
import { BreadCrumbListJsonLd } from '@/shared/scripts/StructuredDataMarkup'

export type ColorVariant = 'default' | 'secondary'

export type PageBreadcrumbComponentProps = {
  breadcrumbs: BreadcrumbItem<typeof Link>[]
  homeUrl: string
  colors: ColorProps<'background', never>
}

export const PageBreadcrumbComponent = ({
  breadcrumbs,
  homeUrl,
  colors,
}: PageBreadcrumbComponentProps) => {
  const { colors: themeColors } = useTheme()
  const t = useTranslations('breadcrumb')
  const url = typeof window !== 'undefined' ? window.location.origin : ''

  return (
    <Box pv={spacing.xxxs} backgroundColor={colors.background}>
      {BreadCrumbListJsonLd(`${url}${countryConfig.basePath}`, breadcrumbs)}
      <Grid data-testid="breadcrumbs">
        <Breadcrumb
          tag={Link}
          items={breadcrumbs}
          color={themeColors.textLink}
          disableVisited={true}
          aria-label={t('ariaLabel')}
          homeAriaLabel={t('homeAriaLabel')}
          homeUrl={homeUrl}
          mv={0}
        />
      </Grid>
    </Box>
  )
}
