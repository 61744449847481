'use client'

import { Col, Row, spacing } from '@fortum/elemental-ui'

import { ContentfulImage2 } from '@/shared/components/ContentfulImage2'
import { rejectNil } from '@/shared/utils/array'
import { getSizesForColumns } from '@/shared/utils/image'

import { BenefitHeader } from './BenefitHeader'
import { BenefitItem } from './BenefitItem'
import type { CommonBenefitsProps } from '../types'

/**
 * Variant of the benefits section. Shows two columns, one with items and the other with the primary image.
 * Each item shows an icon, title and content.
 */
export const BenefitsSplit = ({
  benefitsSectionItemsCollection,
  simpleContent,
  reversedLayout,
  image,
  title,
  topic,
}: CommonBenefitsProps) => (
  <Row
    display="flex"
    flexDirection={{ default: 'column', l: reversedLayout ? 'row-reverse' : 'row' }}
    rowGap={spacing.xs}
  >
    <Col l={6} gap={spacing.xs} display="flex" flexDirection="column">
      {(title || simpleContent) && (
        <BenefitHeader title={title} content={simpleContent} topic={topic} />
      )}
      {benefitsSectionItemsCollection?.items &&
        rejectNil(benefitsSectionItemsCollection.items).map(({ sys, ...fields }) => (
          <BenefitItem key={sys.id} {...fields} content={fields.content} />
        ))}
    </Col>
    <Col l={6}>
      {image && (
        <ContentfulImage2
          image={image}
          boxProps={{ aspectRatio: { default: '16/9', l: '4/5' }, borderRadius: spacing.xxxxs }}
          sizes={getSizesForColumns({ l: 6 })}
        />
      )}
    </Col>
  </Row>
)
