'use client'

import { forwardRef } from 'react'

import { Box, ContentText, spacing } from '@fortum/elemental-ui'

import type { CampaignConfigurationEntry } from '@/shared/contentful/types'
import type {
  EnrichedContractProduct,
  EnrichedContractTemplate,
} from '@/shared/services/campaignDataResolver'
import { useGlobalStore } from '@/shared/store/provider'

import { StickyFooter } from './StickyFooter'
import { Price } from '../Price'
import { PriceBreakdownInfoIcon } from '../PriceBreakdownInfoIcon'
import { PurchaseButton } from '../PurchaseButton'

type ContractStickyFooterProps = {
  /**
   * Defines if the footer should appear from the bottom.
   */
  isShown: boolean

  /**
   * Price and marketing information about contract.
   */
  contractTemplate: EnrichedContractTemplate
  /**
   * Contract templates group.
   */
  contractProduct: EnrichedContractProduct

  /**
   * Campaign configuration of given contractTemplate.
   */
  campaignConfiguration: CampaignConfigurationEntry

  /**
   * Set to true when order button should be hidden
   */
  purchaseButtonHidden?: boolean
}

/**
 * Wrapper around StickyFooter to separate styles and filling for contract hero section.
 */
export const ContractStickyFooter = forwardRef<HTMLDivElement, ContractStickyFooterProps>(
  (
    { isShown, contractTemplate, purchaseButtonHidden, contractProduct, campaignConfiguration },
    ref,
  ) => {
    const { cfData } = contractProduct
    const { deliveryArea, estimatedConsumption } = useGlobalStore((store) => store.housingInfo)
    return (
      <StickyFooter isShown={isShown} ref={ref}>
        <Box
          display="flex"
          flexWrap="nowrap"
          gap={spacing.xxs}
          flexDirection={{ default: 'column', l: 'row' }}
        >
          {/* Contract information row */}
          <Box flex="1 1 auto" display="flex" gap={spacing.xxs} alignItems="center">
            <Box flex="0 0 auto">
              <Box display="flex" gap={spacing.xxxs} alignItems="center">
                <ContentText size="l">{cfData.title}</ContentText>
                {deliveryArea && estimatedConsumption && (
                  <PriceBreakdownInfoIcon
                    contractTemplate={contractTemplate}
                    contractProduct={contractProduct}
                    campaignConfiguration={campaignConfiguration}
                  />
                )}
              </Box>
            </Box>

            <Box flex="1 1 auto" textAlign="right">
              <Price
                campaignConfiguration={campaignConfiguration}
                contractProduct={contractProduct}
                contractTemplate={contractTemplate}
                mode="minimal"
                priceBreakdownMode="withFooter"
              />
            </Box>
          </Box>

          {/* Contract purchase button row */}
          {!purchaseButtonHidden && (
            <Box
              flex="0 0 auto"
              textAlign={{ default: 'center', m: 'inherit' }}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <PurchaseButton
                contractTemplate={contractTemplate}
                contractProduct={contractProduct}
                campaignConfiguration={campaignConfiguration}
              />
            </Box>
          )}
        </Box>
      </StickyFooter>
    )
  },
)

ContractStickyFooter.displayName = 'ContractStickyFooter'
