'use client'

import { Col, Row, Section, spacing } from '@fortum/elemental-ui'

import { RichText } from '@/shared/components/RichText'
import type { TextSectionEntry } from '@/shared/contentful/types'
import { useTheme } from '@/shared/hooks/useTheme'

export type TextSectionProps = TextSectionEntry
type ColorVariant = 'default' | 'secondary'
/**
 * Section for showing rich text content.
 */
export const TextSection = ({ colorVariant = 'default', content }: TextSectionProps) => {
  const { colors } = useTheme()

  const backgroundColors: Record<ColorVariant, string> = {
    default: colors.backgroundPrimary,
    secondary: colors.backgroundSecondary,
  }

  return (
    <Section backgroundColor={backgroundColors[colorVariant as ColorVariant]} pv={spacing.m}>
      <Row justifyContent="center">
        <Col l={8}>
          <RichText document={content} />
        </Col>
      </Row>
    </Section>
  )
}
