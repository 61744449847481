'use client'

import { useEffect, useMemo } from 'react'

import { Box, Col, ContentText, Row, spacing } from '@fortum/elemental-ui'

import { getPriceBaseOnCustomerType } from '@/open-web/utils/getPriceBaseOnCustomerType'
import type {
  CampaignConfigurationEntry,
  ContractHeroSectionEntry,
} from '@/shared/contentful/types'
import type {
  EnrichedContractProduct,
  EnrichedContractTemplate,
} from '@/shared/services/campaignDataResolver'
import { useGlobalStore } from '@/shared/store/provider'
import type { CustomerType } from '@/shared/store/slices/selectedContractSlice'
import { SectionError } from '@/shared/utils/errorClasses'
import {
  getCurrency,
  getPriceElements,
  mapContractItemToGTM,
  useDeliveryInfo,
} from '@/shared/utils/gtm/gtmHelper'
import { contractItemsEvent } from '@/shared/utils/sendGTMEvent'

import { BulletList, ListItem } from '../../BulletList'
import { ContentfulImage } from '../../ContentfulImage'
import { Price } from '../../Price'
import { getSelectedAddonsTariffNos } from '../../PriceBreakdown/utils'
import { PricePortal } from '../../PricePortal'
import { PurchaseButton } from '../../PurchaseButton'
import { StyledHeading } from '../../StyledHeading'

export type ContractHeroProps = Omit<
  ContractHeroSectionEntry,
  'contract' | 'campaignConfiguration' | 'sys'
> & {
  contractTemplate: EnrichedContractTemplate
  contractProduct: EnrichedContractProduct
  campaignConfiguration: CampaignConfigurationEntry
}

/**
 * A hero component to be used in the section for contract pages.
 */
export const ContractHero: React.FC<ContractHeroProps> = (props) => {
  const { image, contractProduct, contractTemplate, campaignConfiguration } = props
  const { description, sellingPoints } = contractTemplate.cfData
  const {
    cfData: { title },
  } = contractProduct
  const uspList = sellingPoints?.map((sellingPoint, i) => (
    <ListItem key={i}>{sellingPoint}</ListItem>
  ))

  if (
    contractTemplate.customerType !== 'ENTERPRISE' &&
    contractTemplate.customerType !== 'PRIVATE'
  ) {
    throw new SectionError(
      'contractHero',
      `${contractTemplate.templateNo} has UNKNOWN customerType. Contract has bad configuration. Can not be sold!`,
    )
  }

  const customerType = contractTemplate.customerType.toLocaleLowerCase() as CustomerType
  const selectedContract = useGlobalStore((state) => state.selectedContract)
  const { selectedAddons } = selectedContract[customerType]

  const { deliveryArea, estimatedConsumption, deliveryStartDate } = useDeliveryInfo()
  const {
    delivery: { deliveryAddressType },
  } = useGlobalStore((store) => store.checkoutFlow)

  const { customerType: gtmCustomerType } = useGlobalStore((store) => store.gtm)

  const selectedAddonsTariffNos = useMemo(
    () => (!contractTemplate ? [] : getSelectedAddonsTariffNos(contractTemplate, selectedAddons)),
    [contractTemplate, selectedAddons],
  )

  useEffect(() => {
    const priceElements = getPriceElements(
      contractTemplate,
      deliveryArea,
      estimatedConsumption,
      selectedAddonsTariffNos,
      deliveryStartDate,
    )
    const mappedContractItem = mapContractItemToGTM(
      contractTemplate,
      priceElements,
      deliveryAddressType,
      gtmCustomerType,
    )

    contractItemsEvent(
      'view_item',
      {
        value: getPriceBaseOnCustomerType(
          priceElements.energyPriceElements?.estimatedMonthlyCostWithDiscounts,
          customerType,
        ),
        currency: getCurrency(deliveryArea),
      },
      [mappedContractItem],
    )
  }, [contractTemplate])

  return (
    <Row>
      <Col l={6} xl={5} marginBottom={{ default: spacing.xs, l: 'none' }}>
        {image && (
          <ContentfulImage
            wrapperProps={{
              position: 'relative',
              width: '100%',
              height: { default: '240px', m: '360px', l: '100%' },
            }}
            imageEntry={image}
            style={{ objectFit: 'contain', objectPosition: 'top center' }}
            priority
            maxWidth="900"
          />
        )}
      </Col>
      <Col l={6} xl={7} gap={spacing.xs} display="flex" flexDirection="column">
        <StyledHeading level={1}>{title}</StyledHeading>
        {description && <ContentText>{description}</ContentText>}
        {uspList && <BulletList>{uspList}</BulletList>}

        <PricePortal />

        <Price
          contractTemplate={contractTemplate}
          campaignConfiguration={campaignConfiguration}
          contractProduct={contractProduct}
          priceBreakdownMode="withFooter"
          shouldShowPricePlaceholder
        />

        <Box textAlign={{ default: 'center', m: 'left' }}>
          <PurchaseButton
            contractTemplate={contractTemplate}
            campaignConfiguration={campaignConfiguration}
            contractProduct={contractProduct}
          />
        </Box>
      </Col>
    </Row>
  )
}
