'use client'

import { Col, Row, spacing } from '@fortum/elemental-ui'

import type { CtaEntry } from '@/shared/contentful/types'

import { FormattedCtaBlock } from './FormattedCtaBlock'
import type { ColorVariant } from '../types'
import { getBlockColorVariant } from '../utils'

export type CtaGridWithSecondCtaProps = {
  primaryCta: CtaEntry
  secondaryCta: CtaEntry
  colorVariant?: ColorVariant | null
  isLayoutReversed?: boolean | null
}

export const CtaGridWithSecondCta = ({
  primaryCta,
  secondaryCta,
  colorVariant,
  isLayoutReversed,
}: CtaGridWithSecondCtaProps) => {
  return (
    <Row
      rowGap={spacing.xs}
      flexDirection={{ default: 'column', l: isLayoutReversed ? 'row-reverse' : 'row' }}
    >
      <Col l={6}>
        <FormattedCtaBlock
          cta={primaryCta}
          colorVariant={getBlockColorVariant(colorVariant, false)}
        />
      </Col>
      <Col l={6}>
        <FormattedCtaBlock
          cta={secondaryCta}
          colorVariant={getBlockColorVariant(colorVariant, true)}
        />
      </Col>
    </Row>
  )
}
