import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'
import type { Document } from '@contentful/rich-text-types'

import type { BreadcrumbItem } from '@fortum/elemental-ui'

import type { RichTextDocument } from '../components/RichText'
import type { Link } from '../navigation'
import { getBreadcrumbListJsonLd, getFaqPageJsonLd, getOrganizationJsonLd } from '../utils/jsonLd'

export const OrganizationJsonLd = () => {
  const jsonLd = getOrganizationJsonLd()

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd) }}
    />
  )
}

export const BreadCrumbListJsonLd = (url: string, items: BreadcrumbItem<typeof Link>[]) => {
  const itemListElements = items.map((item) => ({
    id: item.params?.href ? `${url}${item.params.href}` : undefined,
    name: item.title,
  }))
  itemListElements.unshift({ id: `${url}`, name: 'Fortum' })

  const jsonLd = getBreadcrumbListJsonLd(itemListElements)

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd) }}
    />
  )
}

type FaqCommonFields = {
  content?: RichTextDocument | null
  title?: string | null
}

export const FaqPageJsonLd = (
  title: string | undefined | null,
  items: FaqCommonFields[] | undefined,
) => {
  const mainEntities = items?.map((item) => {
    //TODO: Better typings for documentToPlainTextString?
    const answer = item.content ? documentToPlainTextString(item.content.json as Document) : ''
    return {
      '@type': 'Question',
      name: item.title || '',
      acceptedAnswer: {
        '@type': 'Answer',
        text: answer,
      },
    }
  })

  const jsonLd = getFaqPageJsonLd(title || '', mainEntities || [])

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd) }}
    />
  )
}
