'use client'

import type { CSSProperties, PropsWithChildren } from 'react'

import type { CommonBoxProps } from '@fortum/elemental-ui'
import { Box, Section as ElementalSection } from '@fortum/elemental-ui'

export type SectionProps = CommonBoxProps & {
  /**
   * Contentful field id - `section` as a default value.
   */
  fieldId?: string
  /**
   * Contentful's entry id of the section.
   */
  entryId?: string
  /**
   * Background image of the section - useful in a hero section.
   */
  background?: string
  /**
   * Also supports any css properties that can be applied to a section wrapper.
   */
  style?: CSSProperties
  /**
   * Basic colors configuration for the section.
   */
  colors?: ColorProps<never, 'background'>
}

/**
 * Generic section component.
 * Intended to be used as a wrapper for grid based layouts across the application.
 * Also contains a wrapper for a background image to be used as a hero section.
 *
 * TODO - currently supports some basic configuration, will be extended once we have a better idea of the use cases.
 */
export const Section = ({
  children,
  background,
  colors = {},
  ...props
}: PropsWithChildren<SectionProps>) => (
  <Box
    overflow="hidden"
    style={{
      backgroundSize: 'cover',
      backgroundRepeat: 'repeat, no-repeat',
      backgroundPosition: 'center',
      backgroundImage: background && `url(${background})`,
      backgroundColor: colors?.background,
      background: colors?.background,
    }}
  >
    <ElementalSection {...props}>{children}</ElementalSection>
  </Box>
)
