'use client'

import type { CommonBoxProps } from '@fortum/elemental-ui'
import { Box, ContentText, spacing } from '@fortum/elemental-ui'

import { StyledHeading } from '@/shared/components/StyledHeading'
import { useTheme } from '@/shared/hooks/useTheme'

export type BenefitHeaderProps = {
  /**
   * Uppercase text above title
   */
  topic?: string | null
  /**
   * Heading text
   */
  title?: string | null
  /**
   * content text
   */
  content?: string | null
  /**
   * Wrapper props
   */
  boxProps?: CommonBoxProps
}

/**
 * Benefit section header.
 */
export const BenefitHeader = ({ topic, title, content, boxProps }: BenefitHeaderProps) => {
  const { colors } = useTheme()

  return (
    <Box display="flex" flexDirection="column" {...boxProps}>
      {topic && (
        <ContentText color={colors.textPositive} style={{ textTransform: 'uppercase' }}>
          {topic}
        </ContentText>
      )}
      {title && (
        <StyledHeading level={2} lineHeight="compact" mb={spacing.xxxs}>
          {title}
        </StyledHeading>
      )}
      {content && <ContentText>{content}</ContentText>}
    </Box>
  )
}
