'use client'

import { Box, spacing } from '@fortum/elemental-ui'

import type { IconId } from '@/shared/components/Icon'
import { Icon } from '@/shared/components/Icon'
import type { RichTextDocument } from '@/shared/components/RichText'
import { RichText } from '@/shared/components/RichText'
import { StyledHeading } from '@/shared/components/StyledHeading'
import { useTheme } from '@/shared/hooks/useTheme'

export type BenefitItemProps = {
  /**
   * Marketing icon
   */
  icon?: string | null
  /**
   * Primary content feature text
   */
  title?: string | null
  /**
   * Secondary content feature text
   */
  content?: RichTextDocument | null
}

/**
 * Benefits section item.
 */
export const BenefitItem = ({ icon, title, content }: BenefitItemProps) => {
  const { colors } = useTheme()

  return (
    <Box display="flex" flexDirection="column" gap={spacing.xxxs}>
      <Box display="flex" alignItems="center" gap={spacing.xxxs} textColor={colors.textLarge}>
        {icon && <Icon icon={icon as IconId} size={48} />}
        <StyledHeading level={3} styledAs={5} lineHeight="compact">
          {title}
        </StyledHeading>
      </Box>
      {content && <RichText document={content} />}
    </Box>
  )
}
