'use client'

import { Fragment } from 'react'

import { Col, Row } from '@fortum/elemental-ui'

import { Carousel } from '@/shared/components/Carousel'
import { Review } from '@/shared/components/Review'
import { Section } from '@/shared/components/Section'
import type { ReviewsSectionEntry } from '@/shared/contentful/types'
import { useTheme } from '@/shared/hooks/useTheme'

export type ReviewSectionProps = ReviewsSectionEntry
/**
 * Carousel component for multiple Reviews
 */
export const ReviewSection = ({
  reviewsSectionItemsCollection,
  colorVariant,
}: ReviewSectionProps) => {
  const { colors } = useTheme()
  let background = colors.backgroundPrimary
  const isLightOnDark = colorVariant === 'inverted'
  if (colorVariant === 'inverted') {
    background = colors.backgroundLightOnDarkGradient
  }
  if (colorVariant === 'secondary') {
    background = colors.backgroundSecondary
  }

  return (
    <Section colors={{ background }}>
      <Row>
        <Col>
          <Carousel
            elementsPerSlide={{ default: 1, xs: 1, s: 2, m: 2, l: 2, xl: 3, xxl: 3 }}
            isChildSetWidth
            colors={
              isLightOnDark
                ? {
                    button: colors.textLightOnDark,
                    dot: colors.backgroundPrimary,
                    dotActive: colors.textLightOnDarkAccent,
                  }
                : undefined
            }
          >
            {reviewsSectionItemsCollection?.items?.map((item, i) => (
              <Fragment key={i}>
                {item && <Review {...item} isLightOnDark={isLightOnDark} />}
              </Fragment>
            ))}
          </Carousel>
        </Col>
      </Row>
    </Section>
  )
}
