'use client'

import type { ReactNode } from 'react'
import { Children, useEffect, useState } from 'react'

import type { BoxProps } from '@fortum/elemental-ui'
import { Box, Col, Row, spacing } from '@fortum/elemental-ui'

import type { ActiveBreakpoints } from '@/shared/hooks/useBreakpoints'
import { useResponsiveValue } from '@/shared/hooks/useBreakpoints'
import { useSwipeHandlers } from '@/shared/hooks/useSwipeHandlers'

import { Stepper } from './Stepper'

export type CarouselProps = {
  /**
   * Colors for the stepper
   */
  colors?: ColorProps<never, 'button' | 'dot' | 'dotActive' | 'background'>
  /**
   * Number of elements per slide
   */
  elementsPerSlide?: number | { [key in ActiveBreakpoints]?: number }
  /**
   * Whether the carousel is active, if false shows all elements
   */
  carouselActive?: boolean | { [key in ActiveBreakpoints]?: boolean }

  /**
   * Props for the wrapper to add additional styling
   */
  wrapperProps?: BoxProps
  /**
   * Whether the stepper is above the carousel
   */
  stepperPosition?: 'above' | 'beneath'
  /**
   * Children to be rendered
   */
  children?: ReactNode
  /**
   * Whether the stepper is cycled. If true, it indicates that both prev and next buttons
   * are inactive when it reaches the start/end.
   */
  cycled?: boolean
  /**
   * Whether the child components have their own width that should be kept.
   */
  isChildSetWidth?: boolean
  /**
   * Set to true if only exact number of elements should be presented per slide - next/prev elements won't be partially showed
   */
  isOnlyActiveElementsShown?: boolean
  /**
   * Whether to show part of the previous item
   */
  showPrevious?: string
}

/**
 * A component that takes in child components as an array and renders them in a carousel
 */
export const Carousel = ({
  children,
  colors = {},
  elementsPerSlide = { default: 1, xs: 1, s: 1, m: 2, l: 2, xl: 3, xxl: 3 },
  carouselActive = true,
  wrapperProps,
  stepperPosition = 'beneath',
  cycled = true,
  isChildSetWidth,
  isOnlyActiveElementsShown,
  showPrevious,
}: CarouselProps) => {
  const [index, setIndex] = useState(0)
  const [transformValue, setTransformValue] = useState('translateX(0%)')

  const numItems = Children.count(children)

  const currentElementsPerSlide = useResponsiveValue(elementsPerSlide)
  let isCarouselActive = useResponsiveValue(carouselActive)

  // Check conditions and set isCarouselActive accordingly
  if (currentElementsPerSlide < 1 || currentElementsPerSlide > 4) {
    isCarouselActive = false
  }

  if (currentElementsPerSlide >= numItems) {
    isCarouselActive = false
  }

  const isActiveElement = (elementIndex: number) =>
    elementIndex >= index && elementIndex < index + currentElementsPerSlide

  const changeSlide = (change: number) => {
    setIndex((prevIndex) => {
      const newIndex = prevIndex + change
      return newIndex
    })
  }

  const handleNext = () => {
    if (isCarouselActive) {
      if (index < numItems - currentElementsPerSlide) {
        changeSlide(1)
      } else {
        setIndex(0)
      }
    }
  }

  const handlePrevious = () => {
    if (isCarouselActive) {
      if (index > 0) {
        changeSlide(-1)
      } else {
        setIndex(numItems - currentElementsPerSlide)
      }
    }
  }

  const { handleSwipeStart, handleSwipeEnd } = useSwipeHandlers(handleNext, handlePrevious)

  //Helper function to help with resize events
  const updateCarouselTransform = () => {
    // Early return if carousel is not active.
    if (!isCarouselActive) {
      return
    }

    // Calculate the transform value using 100 / currentElementsPerSlide
    const newTransformValue = `translateX(-${index * 100}%)`
    setTransformValue(newTransformValue)
  }
  useEffect(updateCarouselTransform, [isCarouselActive, currentElementsPerSlide, index])

  let carouselInner
  switch (isCarouselActive) {
    case true:
      carouselInner = (
        <>
          {stepperPosition === 'above' && (
            <Box mb={spacing.xxxs}>
              <Row display="flex" justifyContent="center">
                <Col>
                  <Stepper
                    elements={numItems}
                    activeElementIndex={index}
                    activeElements={currentElementsPerSlide}
                    onPreviousClick={handlePrevious}
                    onNextClick={handleNext}
                    cycled={cycled}
                    colors={colors}
                  />
                </Col>
              </Row>
            </Box>
          )}
          <Row display="flex" justifyContent="center" alignItems="center">
            <Box
              display="flex"
              maxWidth="100%"
              onTouchStart={handleSwipeStart}
              onTouchEnd={handleSwipeEnd}
              paddingLeft={{ m: showPrevious && !isActiveElement(0) ? showPrevious : 0 }}
            >
              {Children.map(children, (child, i) => (
                <Col
                  width={isChildSetWidth ? 'auto' : `100%`}
                  flexShrink={0}
                  transition="transform 0.3s ease-in-out"
                  transform={transformValue}
                  {...(isOnlyActiveElementsShown
                    ? {
                        transition:
                          'opacity 0.3s ease-in-out, visibility 0.3s ease-in-out, transform 0.3s ease-in-out',
                        style: {
                          visibility: isActiveElement(i) ? 'visible' : 'hidden',
                          opacity: isActiveElement(i) ? 1 : 0,
                        },
                      }
                    : undefined)}
                >
                  <Box
                    display="flex"
                    justifyContent="center"
                    height="100%"
                    style={{ boxSizing: 'border-box' }}
                    key={i}
                  >
                    {child}
                  </Box>
                </Col>
              ))}
            </Box>
          </Row>
          {stepperPosition === 'beneath' && (
            <Box mt={spacing.xs}>
              <Row display="flex" justifyContent="center">
                <Col>
                  <Stepper
                    elements={numItems}
                    activeElementIndex={index}
                    activeElements={currentElementsPerSlide}
                    onPreviousClick={handlePrevious}
                    onNextClick={handleNext}
                    cycled={cycled}
                    colors={colors}
                  />
                </Col>
              </Row>
            </Box>
          )}
        </>
      )
      break
    case false:
      carouselInner = (
        <Row display="flex">
          {Children.map(children, (child, i) => (
            <Col
              width={isChildSetWidth ? 'auto' : `${100 / currentElementsPerSlide}%`}
              flexShrink={0}
            >
              <Box
                display="flex"
                justifyContent="center"
                pv={spacing.xs}
                height="100%"
                style={{ boxSizing: 'border-box' }}
                key={i}
              >
                {child}
              </Box>
            </Col>
          ))}
        </Row>
      )

    default:
      break
  }

  return (
    <Box style={{ background: colors.background }} {...wrapperProps}>
      {carouselInner}
    </Box>
  )
}
