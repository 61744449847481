'use client'

import { Quote } from '@/shared/components/Quote'
import { Section } from '@/shared/components/Section'
import type { QuoteSectionEntry } from '@/shared/contentful/types'

type QuoteSectionProps = QuoteSectionEntry

/**
 * Customer's quote section element.
 */
export const QuoteSection = ({ quote }: QuoteSectionProps) => (
  <Section justifyContent="center">{quote && <Quote {...quote} />}</Section>
)
