'use client'

import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'
import type { Document } from '@contentful/rich-text-types'

import { Box, ButtonPill, Col, Row, spacing } from '@fortum/elemental-ui'

import { MediaTestimonial } from '@/shared/components/MediaTestimonial'
import { RichText } from '@/shared/components/RichText'
import { Section } from '@/shared/components/Section'
import { StyledHeading } from '@/shared/components/StyledHeading'
import type {
  ImageEntry,
  LinkEntry,
  MediaTestimonialSectionEntry,
  VideoEntry,
} from '@/shared/contentful/types'
import { getLinkEntryUrl } from '@/shared/contentful/utils'
import { useTheme } from '@/shared/hooks/useTheme'
import { Link } from '@/shared/navigation'

export type MediaTestimonialSectionProps = MediaTestimonialSectionEntry

/**
 * Returns image or video entry.
 */
const getMediaEntries = (media: MediaTestimonialSectionEntry['media']) => {
  if (media?.__typename === 'Image') {
    return { imageEntry: media as ImageEntry, videoEntry: undefined }
  }
  return { imageEntry: undefined, videoEntry: media as VideoEntry }
}

/**
 * Returns number of columns for paragraph section.
 */
const getParagraphColumns = (
  primaryLink?: LinkEntry | null,
  secondaryLink?: LinkEntry | null,
): number => {
  if (primaryLink && secondaryLink) {
    return 4
  }
  if (primaryLink || secondaryLink) {
    return 2
  }
  return 0
}

/**
 * Section with cta, customers quote and image or video.
 */
export const MediaTestimonialSection = (props: MediaTestimonialSectionProps) => {
  const {
    media,
    quote,
    title,
    content: mediaContent,
    primaryLink,
    secondaryLink,
    colorVariant,
  } = props

  const { author, authorDescription, simpleContent: quoteContent, name = '' } = quote || {}
  const theme = useTheme()

  const { imageEntry, videoEntry } = getMediaEntries(media)
  const paragraphColumns = getParagraphColumns(primaryLink, secondaryLink)

  const links = [primaryLink, secondaryLink].filter(Boolean)
  const { backgroundPrimary, backgroundSecondary } = theme.colors

  return (
    <Section
      justifyContent="center"
      textAlign="center"
      backgroundColor={colorVariant === 'secondary' ? backgroundSecondary : backgroundPrimary}
    >
      {title && (
        <Row justifyContent="center" mb={{ default: spacing.xs }}>
          <Col l={10}>
            <StyledHeading level={2} styledAs={4}>
              {title}
            </StyledHeading>
          </Col>
        </Row>
      )}
      {/* documentToPlainTextString is used because plain content is a multilevel object that will be truthy even if its content is empty */}
      {/**TODO: Better typings??? */}
      {mediaContent && documentToPlainTextString(mediaContent.json as Document) && (
        <Row
          justifyContent="center"
          mb={{ default: spacing.xs }}
          gap={{ default: spacing.xs, l: spacing.none }}
        >
          <Col l={10}>
            <RichText document={mediaContent} />
          </Col>
        </Row>
      )}
      {links.length > 0 && (
        <Row
          justifyContent="center"
          mb={{ default: spacing.xs }}
          gap={{ default: spacing.xs, l: spacing.none }}
        >
          <Col xxs={12} l={paragraphColumns}>
            <Box
              display="flex"
              justifyContent="center"
              flexDirection={{ default: 'column', s: 'row' }}
              gap={{ default: spacing.xxs }}
            >
              {primaryLink && (
                <ButtonPill
                  tag={Link}
                  href={getLinkEntryUrl(primaryLink)}
                  width={{ default: '100%', s: 'auto', l: '100%' }}
                  whiteSpace="nowrap"
                >
                  {primaryLink.label}
                </ButtonPill>
              )}
              {secondaryLink && (
                <ButtonPill
                  tag={Link}
                  href={getLinkEntryUrl(secondaryLink)}
                  width={{ default: '100%', s: 'auto', l: '100%' }}
                  whiteSpace="nowrap"
                  status="secondary"
                >
                  {secondaryLink?.label}
                </ButtonPill>
              )}
            </Box>
          </Col>
        </Row>
      )}
      <Row justifyContent="center">
        <Col xxs={12}>
          <MediaTestimonial
            name={name}
            author={author}
            authorDescription={authorDescription}
            simpleContent={quoteContent}
            imageEntry={imageEntry}
            videoEntry={videoEntry}
          />
        </Col>
      </Row>
    </Section>
  )
}
