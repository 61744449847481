import { Fragment, useState } from 'react'
import styled from 'styled-components'

import { ContentText, LazyIcon, shadows, spacing } from '@fortum/elemental-ui'

import type { IconId } from '@/shared/components/Icon'
import { RichText } from '@/shared/components/RichText'
import { StyledHeading } from '@/shared/components/StyledHeading'
import type { ProductDetailsItemEntry, ProductDetailsSectionEntry } from '@/shared/contentful/types'

export type ProductDetailsSectionProps = ProductDetailsSectionEntry

type Props = {
  items: ProductDetailsItemEntry[]
}

/**
 * Product details section accordion for desktop viewports.
 */
export const ProductDetailsAccordionDesktop = ({ items }: Props) => {
  const [expandedId, setExpandedId] = useState(items.at(0)?.sys.id)

  return (
    <Accordion>
      {items.map(({ sys: { id }, icon, title, contentTitle, content }) => {
        const isExpanded = expandedId === id
        const panelId = `product-details-panel-desktop-${id}`

        return (
          <Fragment key={id}>
            <AccordionHeader
              aria-expanded={isExpanded}
              aria-controls={panelId}
              onClick={() => setExpandedId(id)}
              data-testid="product-details-accordion-header"
            >
              <LazyIcon iconId={icon as IconId} size={48} />
              <ContentText size="l">{title}</ContentText>
            </AccordionHeader>
            <AccordionPanel
              hidden={!isExpanded}
              $itemCount={items.length}
              role="region"
              id={panelId}
            >
              <AccordionPanelCard tabIndex={0}>
                {contentTitle && (
                  <StyledHeading level={3} styledAs={2} mb={spacing.xxs}>
                    {contentTitle}
                  </StyledHeading>
                )}
                <RichText document={content} />
              </AccordionPanelCard>
            </AccordionPanel>
          </Fragment>
        )
      })}
    </Accordion>
  )
}

const Accordion = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: ${spacing.xs};
`

const AccordionHeader = styled.button`
  padding: ${spacing.xxs};
  display: flex;
  align-items: center;
  gap: ${spacing.xxs};
  text-align: left;
  z-index: 1;
  grid-column: span 4;
  border-radius: ${spacing.xxxs};

  &:hover[aria-expanded='false'] {
    background-color: ${({ theme }) => theme.colors.backgroundSecondary};
    box-shadow: ${shadows.m};
  }

  &[aria-expanded='true'] {
    background-color: ${({ theme }) => theme.colors.backgroundPrimary};
    box-shadow: ${shadows.m};
  }

  /* Prevent box-shadow from overriding global focus style */
  &:focus-visible {
    box-shadow: 0 0 0 4px ${({ theme }) => theme.tokens.focus.primary};
  }
`

const AccordionPanel = styled.div<{ $itemCount: number }>`
  min-width: 0;
  grid-column: 5 / span 8;
  grid-row: 1 / span ${({ $itemCount }) => $itemCount + 1};
`

const AccordionPanelCard = styled.div`
  padding: ${spacing.m};
  background-color: ${({ theme }) => theme.colors.backgroundPrimary};
  box-shadow: ${shadows.m};
  border-radius: ${spacing.xxxs};
  min-height: 20rem;
  max-height: min(calc(100dvh - 8rem), 48rem);
  overflow: auto;

  /* Prevent box-shadow from overriding global focus style */
  &:focus-visible {
    box-shadow: 0 0 0 4px ${({ theme }) => theme.tokens.focus.primary};
  }
`
