'use client'

import { ForwardLink } from '@/shared/components/ForwardLink'
import { KeyLink } from '@/shared/components/KeyLink'
import type { LinkSectionEntry } from '@/shared/contentful/types'
import { isTypeIconLink } from '@/shared/contentful/types/contentTypeGuards'
import { getLinkEntryUrl } from '@/shared/contentful/utils'
import { useTheme } from '@/shared/hooks/useTheme'

export type LinkItemProps = {
  item: NonNullable<LinkSectionEntry['linkSectionItemsCollection']>['items'][number]
  linkVariant: 'button' | 'forwardLink'
}

export const LinkItem = ({ item, linkVariant }: LinkItemProps) => {
  const theme = useTheme()
  if (!item) {
    return null
  }

  const { textLink } = theme.colors
  const { label, page, url } = item || {}
  const icon = isTypeIconLink(item) ? item?.icon : undefined

  return linkVariant === 'forwardLink' ? (
    <ForwardLink
      color={textLink}
      href={getLinkEntryUrl({ page, url })}
      data-testid="link-section-forward-link"
    >
      {label}
    </ForwardLink>
  ) : (
    <KeyLink
      label={label}
      href={getLinkEntryUrl({ page, url })}
      icon={icon}
      dataTestId="link-section-key-link"
    />
  )
}
