'use client'

import React from 'react'

import {
  AnimatedCircle,
  Box,
  ContentText,
  IconArrowRight,
  shadows,
  spacing,
  useBoolean,
} from '@fortum/elemental-ui'

import type { CtaEntry } from '@/shared/contentful/types'
import { getLinkEntryUrl } from '@/shared/contentful/utils'
import { useTheme } from '@/shared/hooks/useTheme'

import { ContentfulImage } from '../../ContentfulImage'
import { RichText } from '../../RichText'
import { StyledHeading } from '../../StyledHeading'
import { StyledLink } from '../../StyledLink'

export type NavigationCardProps = CtaEntry

/**
 * Card component that is used to redirect customer.
 */
export const NavigationCard = ({ image, title, content, primaryLink }: NavigationCardProps) => {
  const theme = useTheme()
  const { value: isCircleVisible, setFalse, setTrue } = useBoolean(false)

  const handleOnFocus = React.useCallback(
    (_event: React.FocusEvent<HTMLDivElement>) => {
      setTrue()
    },
    [setTrue],
  )

  const handleOnBlur = React.useCallback(
    (_event: React.FocusEvent<HTMLDivElement>) => {
      setFalse()
    },
    [setFalse],
  )

  const handleMouseEnter = React.useCallback(
    (_event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      setTrue()
    },
    [setTrue],
  )

  const handleMouseLeave = React.useCallback(
    (_event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      setFalse()
    },
    [setFalse],
  )

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      boxShadow={shadows.s}
      cursor="pointer"
      onFocus={handleOnFocus}
      onBlur={handleOnBlur}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      borderRadius={spacing.xxxs}
    >
      <StyledLink
        href={(primaryLink && getLinkEntryUrl(primaryLink)) ?? '/'}
        style={{ textDecoration: 'none' }}
        color={theme.colors.textLightOnDark}
        noUnderline
        display="flex"
        flexDirection="column"
        flex="1"
      >
        {image && (
          <Box aspectRatio="4/3" position="relative">
            <ContentfulImage
              alt="navigation card image"
              imageEntry={image}
              style={{ borderRadius: `${spacing.xxxs} ${spacing.xxxs} 0 0` }}
              maxWidth="750"
            />
          </Box>
        )}

        <Box
          backgroundColor={theme.colors.backgroundTertiary}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          p={{ default: `${spacing.xs} ${spacing.xxs}`, s: `${spacing.s} ${spacing.xs}` }}
          style={{ borderRadius: `0 0 ${spacing.xxxs} ${spacing.xxxs}` }}
          flex="1"
        >
          <Box>
            {content && <RichText document={content} />}
            {title && (
              <StyledHeading level={4} color={theme.colors.textLightOnDark} pt={spacing.xxxs}>
                {title}
              </StyledHeading>
            )}
          </Box>
          <Box>
            {primaryLink && (
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                flexDirection="row"
                pt={{ default: spacing.xxs, s: spacing.xs }}
              >
                <ContentText textColor={theme.colors.textLightOnDark}>
                  {primaryLink?.label}
                </ContentText>
                <AnimatedCircle
                  color={theme.colors.textLightOnDark}
                  visible={isCircleVisible}
                  slide={isCircleVisible}
                  ml={spacing.xxxs}
                >
                  <IconArrowRight size={24} />
                </AnimatedCircle>
              </Box>
            )}
          </Box>
        </Box>
      </StyledLink>
    </Box>
  )
}
