import { useTranslations } from 'next-intl'
import styled from 'styled-components'

import type { ChipItem } from '@fortum/elemental-ui'
import { Box, Chips, IconCheck, fontSizes, spacing, styles } from '@fortum/elemental-ui'

import { useGlobalStore } from '@/shared/store/provider'
import { getHoverColor } from '@/shared/utils/colors'
import { stepEvent } from '@/shared/utils/sendGTMEvent'

import { Icon } from '../Icon'

type Category = { label?: string | null; value?: number | null }

export type ContractCategoryFilterProps = {
  /**
   * Categories from ContractProduct entry.
   */
  categories: Category[]
  /**
   * Active filters. Maximum one.
   */
  activeFilters?: string[]
  /**
   * Callback triggered when filter is selected. Invoked with an empty array when reset is clicked.
   */
  setActiveFilter: (value: string[]) => void
}

/**
 * Filters for ContractListSection. Single filter might be active only.
 */
export const ContractCategoryFilter = ({
  categories,
  setActiveFilter,
  activeFilters,
}: ContractCategoryFilterProps) => {
  const t = useTranslations('contractTemplateFilter')
  const filters = categories
    .map((category) => ({
      label: category.label,
      value: category.value?.toString(),
    }))
    .filter(isFilter)

  const { housingSize, housingType } = useGlobalStore((state) => state.housingInfo)

  if (!categories?.length) {
    return null
  }

  const handleActiveFilters = (filters: string[]) => {
    setActiveFilter(filters)

    const selectedFilters = filters.map((filter) => {
      const selectedFilter = categories.find((category) => category.value === Number(filter))
      return selectedFilter?.label
    })

    stepEvent('filter_contracts', {
      filter: selectedFilters.join(','),
      housing_type: housingType,
      size: housingSize,
    })
  }

  return (
    <Box
      gap={spacing.xxxs}
      display="flex"
      flexDirection={{ default: 'column', s: 'row' }}
      justifyContent="flex-start"
      alignItems={{ default: 'flex-start', s: 'center' }}
    >
      <UnderlineButton
        disabled={!Boolean(activeFilters?.length)}
        onClick={() => handleActiveFilters([])}
        data-testid="contract-category-filters-reset"
      >
        {t('resetAllFilters')}
      </UnderlineButton>
      <Chips
        shape="rectangular"
        items={filters}
        aria-label="Available filters"
        active={activeFilters}
        onChange={(value) => handleActiveFilters(value)}
        activeIcon={<Icon icon={IconCheck} />}
        data-testid="contract-category-filters"
      />
    </Box>
  )
}

const isFilter = (filter: unknown): filter is ChipItem<string> =>
  Boolean((filter as ChipItem<string>).label && (filter as ChipItem<string>).value)

const UnderlineButton = styled.button`
  color: ${({ disabled, theme }) => (disabled ? theme.colors.textDisabled : theme.colors.textLink)};
  border: none;
  background: none;
  text-decoration: underline;
  font-family: ${styles.fontFamily.base};
  font-size: ${fontSizes.m.default};
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};

  &:hover {
    color: ${({ theme, disabled }) =>
      !disabled ? getHoverColor(theme, theme.colors.textLink) : theme.colors.textDisabled};
  }
`
