'use client'

import { useEffect, useMemo, useRef, useState } from 'react'

import { Box, Col, Row, spacing } from '@fortum/elemental-ui'

import { ContractCardConnector } from '@/shared/components/Cards/ContractCardConnector/ContractCardConnector'
import { NavigationCard } from '@/shared/components/Cards/NavigationCard'
import type { ContractCategoryFilterProps } from '@/shared/components/ContractCategoryFilter'
import { ContractCategoryFilter } from '@/shared/components/ContractCategoryFilter'
import { CoopNotification } from '@/shared/components/CoopNotification'
import { Section } from '@/shared/components/Section'
import type {
  CampaignConfigurationEntry,
  ContractListSectionEntry,
  CtaEntry,
} from '@/shared/contentful/types'
import { useTheme } from '@/shared/hooks/useTheme'
import type { EnrichedContractProduct } from '@/shared/services/campaignDataResolver'
import { useGlobalStore } from '@/shared/store/provider'
import {
  getContractProductsAndPrices,
  getCurrency,
  getPriceElements,
  mapContractItemToGTM,
  useDeliveryInfo,
} from '@/shared/utils/gtm/gtmHelper'
import { contractItemsEvent } from '@/shared/utils/sendGTMEvent'

import { getActiveCampaignConfig } from './utils'

export type ContractListSectionProps = Omit<
  ContractListSectionEntry,
  'contracts' | 'ctaCollection' | 'sys' | '__typename'
> & {
  /**
   * Composed data from Common Backend and Shared Contentful.
   */
  contractProducts?: EnrichedContractProduct[]
  /**
   * Campaign Id which will be used to pick default contractTemplate from contractProducts.
   */
  defaultCampaignId?: number | null
  campaignsConfigurations?: CampaignConfigurationEntry[]
  /**
   * Click to action cards, not connected directly with the contract information
   */
  cta?: CtaEntry[] | null
}

/**
 * Section designed to present contract grid with filters.
 */
export const ContractListSection = ({
  contractProducts = [],
  campaignsConfigurations = [],
  cta,
  defaultCampaignId,
}: ContractListSectionProps) => {
  const theme = useTheme()
  const [activeCategories, setActiveCategories] = useState<string[]>()
  // const [filteredContractProducts, setFilteredContractProducts] = useState(contractProducts)
  const contractProductsSameDefaultCampaignId = useMemo(
    () =>
      contractProducts?.length ? getDefaultCampaignIdIfAllHasSame(contractProducts) : undefined,
    [contractProducts],
  )
  const initialLoad = useRef(true)

  const {
    delivery: { deliveryAddressType },
  } = useGlobalStore((store) => store.checkoutFlow)

  const { _coopMembershipId: coopMembershipId, _coopMembershipIdStatus: coopMembershipIdStatus } =
    useGlobalStore((store) => store.partnerFields)

  const { _isHydrated } = useGlobalStore((store) => store.system)

  const { setItemListName } = useGlobalStore((store) => store.gtm)

  const categories = useMemo<ContractCategoryFilterProps['categories']>(
    () =>
      (campaignsConfigurations || [])
        .filter((c) =>
          //If defaultCampaignId is provided use it to determinate valid filters
          //If not if all contract products have same defaultCampaignId filter out that one
          defaultCampaignId
            ? c.campaignId !== defaultCampaignId
            : c.campaignId !== contractProductsSameDefaultCampaignId,
        )
        .map((c) => ({
          label: c?.title || '',
          value: c?.campaignId,
        })),
    [campaignsConfigurations, defaultCampaignId, contractProductsSameDefaultCampaignId],
  )

  const activeCampaignId = Number(activeCategories?.[0]) || defaultCampaignId
  const filteredContractProducts = useMemo(
    () =>
      activeCampaignId
        ? contractProducts.filter((cp) =>
            cp.contractTemplates.some((ct) => ct.campaignId === activeCampaignId),
          )
        : contractProducts,
    [contractProducts, activeCampaignId],
  )

  const activeCampaignConfiguration = activeCampaignId
    ? getActiveCampaignConfig(activeCampaignId, campaignsConfigurations)
    : undefined

  const shouldCoopNotificationRender =
    _isHydrated &&
    activeCampaignConfiguration?.isCoop &&
    (!coopMembershipId ||
      coopMembershipIdStatus === 'invalid' ||
      coopMembershipIdStatus === 'verificationFailed')

  const { deliveryArea, deliveryStartDate, estimatedConsumption } = useDeliveryInfo()

  const customerType = useGlobalStore((store) => store.gtm.customerType)

  useEffect(() => {
    if (initialLoad.current) {
      const products = getContractProductsAndPrices(
        filteredContractProducts,
        defaultCampaignId,
        campaignsConfigurations,
        estimatedConsumption,
        deliveryStartDate,
        deliveryArea,
      )

      const mappedContractItem = products.map((product) => {
        const priceElements = getPriceElements(
          product.contractProduct,
          deliveryArea,
          estimatedConsumption,
          [],
          deliveryStartDate,
        )
        return mapContractItemToGTM(
          product.contractProduct,
          priceElements,
          deliveryAddressType,
          customerType,
        )
      })

      const itemListName = 'find a contract'
      contractItemsEvent(
        'view_item_list',
        {
          item_list_id: itemListName,
          item_list_name: itemListName,
          currency: getCurrency(deliveryArea),
        },
        mappedContractItem,
      )
      setItemListName(itemListName)
      initialLoad.current = false
    }
  }, [initialLoad])

  return (
    <Section backgroundColor={theme.colors.backgroundSecondary} data-testid="contract-list-section">
      <Box display="flex" flexDirection="column" gap={{ default: spacing.xs, s: spacing.m }}>
        {Boolean(filteredContractProducts?.length) && (
          <ContractCategoryFilter
            categories={categories}
            setActiveFilter={setActiveCategories}
            activeFilters={activeCategories}
          />
        )}
        {shouldCoopNotificationRender && (
          <CoopNotification isApiFail={coopMembershipIdStatus === 'verificationFailed'} />
        )}
        <Row justifyContent="center" gap={{ default: `${spacing.m} 0` }}>
          {filteredContractProducts?.map((contractProduct, index) => (
            <Col display="flex" s={6} xl={4} key={`${contractProduct.cfData.name}_${index}`}>
              <ContractCardConnector
                campaignConfiguration={
                  activeCampaignConfiguration ||
                  getActiveCampaignConfig(
                    contractProduct.cfData.defaultCampaignId,
                    campaignsConfigurations,
                  )
                }
                contractProduct={contractProduct}
                order={index}
                isPurchaseDisabled={Boolean(
                  activeCampaignConfiguration?.isCoop && coopMembershipIdStatus !== 'valid',
                )}
              />
            </Col>
          ))}
          {cta?.map((ctaItem, index) => (
            <Col key={`${ctaItem?.name}_${index}`} display="flex" s={6} xl={4}>
              <NavigationCard name="campaign-card" {...ctaItem} />
            </Col>
          ))}
        </Row>
      </Box>
    </Section>
  )
}

/**
 * Return defaultCampaignId if all contract products has same defaultCampaignId
 */
const getDefaultCampaignIdIfAllHasSame = (contractProducts: EnrichedContractProduct[]) => {
  const defaultCampaignId = contractProducts[0].cfData.defaultCampaignId
  if (
    contractProducts.every(
      (cp) => contractProducts[0].cfData.defaultCampaignId === cp.cfData.defaultCampaignId,
    )
  ) {
    return defaultCampaignId
  }
}
