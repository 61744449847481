import { type BreakpointOrDefault, breakpoints } from '@fortum/elemental-ui'

export type ResponsiveSizes = string | Partial<Record<BreakpointOrDefault, string>>

/**
 * Get an img element sizes attribute value from a responsive sizes object.
 */
export const getImageSizesValue = (sizes: ResponsiveSizes): string => {
  if (typeof sizes === 'string') {
    return sizes
  }
  return breakpointsArray
    .map((breakpoint) => {
      const value = sizes[breakpoint]
      if (value === undefined) {
        return
      }
      if (breakpoint === 'default') {
        return value
      }
      return `(min-width: ${breakpoints[breakpoint]}px) ${value}`
    })
    .filter(Boolean)
    .join(', ')
}

const breakpointsArray: BreakpointOrDefault[] = ['xxl', 'xl', 'l', 'm', 's', 'xs', 'default']

const GRID_MAX_WIDTH = 1280

export type ResponsiveColumns = Partial<Record<BreakpointOrDefault, number>>

/**
 * Generates a sizes attribute value for an img element based on the given responsive column counts.
 * This is useful for loading optimal image sizes based on the grid layout.
 */
export const getSizesForColumns = (columns: ResponsiveColumns) => {
  const updatedColumns = { default: 12, l: 12, ...columns }
  const mapped = mapColumnSizes(updatedColumns)
  return getImageSizesValue(mapped)
}

/**
 * Maps a responsive object with column counts to a responsive object with sizes attribute values.
 * Breakpoints up to m use 100vw as max width, l and above use 1280px as max width.
 */
const mapColumnSizes = (columns: ResponsiveColumns) => {
  const result: Partial<Record<BreakpointOrDefault, string>> = {}

  for (const [breakpoint, value] of Object.entries(columns)) {
    if (['default', 'xs', 's', 'm'].includes(breakpoint)) {
      result[breakpoint as BreakpointOrDefault] = `${Math.round(100 * (value / 12))}vw`
    } else if (['l', 'xl', 'xxl'].includes(breakpoint)) {
      result[breakpoint as BreakpointOrDefault] = `${Math.round(GRID_MAX_WIDTH * (value / 12))}px`
    }
  }

  return result
}
