'use client'

import { Box, spacing } from '@fortum/elemental-ui'

import { CtaBlock } from '@/shared/components/CtaBlock'
import type { CtaEntry } from '@/shared/contentful/types'
import { useTheme } from '@/shared/hooks/useTheme'

import type { BlockColorVariant } from '../types'

export type FormattedCtaBlockProps = {
  cta: CtaEntry
  colorVariant: BlockColorVariant
  isFlexLayout?: boolean
  hasImage?: boolean
  isLayoutReversed?: boolean | null
}

export const FormattedCtaBlock = ({
  cta,
  colorVariant,
  isFlexLayout,
  hasImage,
  isLayoutReversed,
}: FormattedCtaBlockProps) => {
  const { colors } = useTheme()

  const borderRadiusL = hasImage
    ? isLayoutReversed
      ? `0 ${spacing.xxxs} ${spacing.xxxs} 0`
      : `${spacing.xxxs} 0 0 ${spacing.xxxs}`
    : `${spacing.xxxs}`

  const backgroundColors: Record<BlockColorVariant, string> = {
    default: colors.backgroundSecondary,
    secondary: colors.backgroundPrimary,
    inverted: colors.backgroundLightOnDark,
  }

  const isInverted = colorVariant === 'inverted'

  return (
    <Box
      flex={{ default: 'none', l: 1 }}
      backgroundColor={backgroundColors[colorVariant]}
      borderRadius={{
        default: hasImage ? `${spacing.xxxs} ${spacing.xxxs} 0 0` : `${spacing.xxxs}`,
        l: borderRadiusL,
      }}
      width={isFlexLayout ? { l: '50%' } : undefined}
      padding={{
        default: `${spacing.xs} ${spacing.xxs}`,
        s: `${spacing.xs} ${spacing.s}`,
        l: `${spacing.m} ${spacing.m}`,
      }}
      height="100%"
      style={{ boxSizing: 'border-box' }}
    >
      <CtaBlock {...cta} isInverted={isInverted} />
    </Box>
  )
}
