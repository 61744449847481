'use-client'

import type { ReactNode } from 'react'

import type { HeadingProps } from '@fortum/elemental-ui'

import { useTheme } from '@/shared/hooks/useTheme'

import { StyledHeading } from '../StyledHeading'

export type HighlightedHeadingProps = HeadingProps & {
  /**
   * Text, with highlighted words surrounded with `*`.
   */
  text: string
  /**
   * Text and highlight colors.
   */
  colors?: ColorProps<never, 'text' | 'highlight'>
}

/**
 * Heading with support for highlighted words.
 */
export const HighlightedHeading = ({
  text,
  colors = {},
  ...headingProps
}: HighlightedHeadingProps) => {
  const theme = useTheme()
  const textColor = colors.text ?? theme.colors.textLightOnDark
  const highlightColor = colors.highlight ?? theme.colors.textLightOnDarkAccent

  const segments = text.split('*').reduce<ReactNode[]>((acc, segment, index) => {
    acc.push(
      <span key={index} style={{ color: index % 2 !== 0 ? highlightColor : undefined }}>
        {segment}
      </span>,
    )
    return acc
  }, [])

  return (
    <StyledHeading level={2} styledAs={1} color={textColor} {...headingProps}>
      {segments}
    </StyledHeading>
  )
}
