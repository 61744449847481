import type { ComponentProps } from 'react'

// eslint-disable-next-line no-restricted-imports
import { ForwardLink as ElementalForwardLink } from '@fortum/elemental-ui'

import { Link } from '@/shared/navigation'

export type ForwardLinkProps = ComponentProps<ElementalForwardLink>

/**
 * Wrapper around `ForwardLink` from Elemental that uses a Next.js link.
 */
export const ForwardLink = (props: ForwardLinkProps) => {
  return <ElementalForwardLink tag={Link} {...props} />
}
