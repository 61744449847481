'use client'

import { Box, Col, Row, spacing } from '@fortum/elemental-ui'

import { Carousel } from '@/shared/components/Carousel'
import { ContentfulImage2 } from '@/shared/components/ContentfulImage2'
import { rejectNil } from '@/shared/utils/array'
import { getSizesForColumns } from '@/shared/utils/image'

import { BenefitHeader } from './BenefitHeader'
import { BenefitItem } from './BenefitItem'
import type { CommonBenefitsProps } from '../types'

/**
 * Variant of the benefits section. Shows items inside carousel.
 * Each item shows an icon, title, content and image.
 */
export const BenefitsCarousel = ({
  benefitsSectionItemsCollection,
  simpleContent,
  reversedLayout,
  title,
  topic,
}: CommonBenefitsProps) => (
  <Box display="flex" flexDirection="column" gap={spacing.xs}>
    {(title || simpleContent) && (
      <BenefitHeader title={title} content={simpleContent} topic={topic} />
    )}
    <Carousel elementsPerSlide={{ default: 1 }} isOnlyActiveElementsShown>
      {benefitsSectionItemsCollection?.items &&
        rejectNil(benefitsSectionItemsCollection.items).map(({ sys, ...fields }) => (
          <Box key={sys.id} flexGrow="1">
            <Row
              display="flex"
              flexDirection={{
                default: 'column-reverse',
                l: reversedLayout ? 'row-reverse' : 'row',
              }}
              rowGap={spacing.xs}
            >
              <Col l={6}>
                {fields.image && (
                  <ContentfulImage2
                    image={fields.image}
                    boxProps={{
                      aspectRatio: { default: '16/9', l: '4/3' },
                      borderRadius: spacing.xxxxs,
                    }}
                    sizes={getSizesForColumns({ l: 6 })}
                  />
                )}
              </Col>
              <Col l={6} display="flex" alignItems="center">
                <BenefitItem {...fields} content={fields.content} />
              </Col>
            </Row>
          </Box>
        ))}
    </Carousel>
  </Box>
)
