'use client'

import { Box, spacing } from '@fortum/elemental-ui'

import { ContentfulImage2 } from '@/shared/components/ContentfulImage2'
import type { CtaEntry } from '@/shared/contentful/types'
import { getSizesForColumns } from '@/shared/utils/image'

import { FormattedCtaBlock } from './FormattedCtaBlock'
import type { ColorVariant } from '../types'
import { getBlockColorVariant } from '../utils'

export type CtaGridWithImageProps = {
  primaryCta: CtaEntry
  colorVariant: ColorVariant
  isLayoutReversed?: boolean | null
}

export const CtaGridWithImage = ({
  primaryCta,
  colorVariant,
  isLayoutReversed,
}: CtaGridWithImageProps) => {
  const hasImage = !!primaryCta.image

  return (
    <Box
      display="flex"
      flexDirection={{ default: 'column', l: isLayoutReversed ? 'row-reverse' : 'row' }}
    >
      <FormattedCtaBlock
        cta={primaryCta}
        colorVariant={getBlockColorVariant(colorVariant, false)}
        isFlexLayout
        hasImage={hasImage}
        isLayoutReversed={isLayoutReversed}
      />
      {primaryCta.image && (
        <Box position="relative" width={{ l: '50%' }} aspectRatio={{ default: '16/9', l: 'auto' }}>
          <ContentfulImage2
            image={primaryCta.image}
            fill
            boxProps={{
              borderRadius: {
                default: `0 0 ${spacing.xxxs} ${spacing.xxxs}`,
                l: isLayoutReversed
                  ? `${spacing.xxxs} 0 0 ${spacing.xxxs}`
                  : `0 ${spacing.xxxs} ${spacing.xxxs} 0`,
              },
            }}
            sizes={getSizesForColumns({ l: 6 })}
          />
        </Box>
      )}
    </Box>
  )
}
