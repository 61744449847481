import { match } from 'ts-pattern'

import type { AnyPageEntry } from '@/shared/contentful/types'
import {
  isArticleEntry,
  isTypeEnterprisePageEntry,
  isTypePageEntry,
} from '@/shared/contentful/types/contentTypeGuards'
import { getPageRoutePath } from '@/shared/contentful/utils'
import { useLocale } from '@/shared/locale'

import type { PageCardAdditionalProps, PageCardProps } from './PageCard'
import { PageCard } from './PageCard'

export type ColorVariant = 'default' | 'inverted'

export type PageCardWrapperProps = PageCardAdditionalProps & {
  page: AnyPageEntry
  showDescription?: boolean
}

/**
 * Component that shows a page card based on a page or article entry.
 */
export const PageCardWrapper = ({
  page,
  showDescription = true,
  ...restProps
}: PageCardWrapperProps) => {
  const locale = useLocale()
  const pageProps = match(page)
    .returnType<PageCardProps>()
    .when(
      (e) => isTypePageEntry(e) || isTypeEnterprisePageEntry(e),
      (page) => ({
        url: getPageRoutePath(page),
        image: page.image,
        title: page.title || '',
        description: showDescription ? page.description || '' : undefined,
      }),
    )
    .when(isArticleEntry, (page) => ({
      url: getPageRoutePath(page, locale),
      image: page.heroImage,
      title: page.title || '',
      topic: page.categoriesCollection?.items
        ?.map((category) => category?.title)
        .filter(Boolean)
        .join('\u00A0\u00A0\u00A0\u00A0'),
      description: showDescription ? (page.description ?? page.lead ?? '') : undefined,
    }))
    .otherwise(() => ({ url: '' }))

  return <PageCard {...pageProps} {...restProps} />
}
